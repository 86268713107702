import React, { useState } from "react";
import "./PosseBoxSub.css";
import Modal from "react-modal";

Modal.setAppElement("#app");

function PosseBoxSub(props) {
  const { dateSub } = props;
  const [checkBox1, setCheckBox1] = useState(true);
  const [checkBox2, setCheckBox2] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [cancelSub, setCancelSub] = useState(false);

  return (
    <>
      {!cancelSub && (
        <div className="set-font-family-res">
          <h2 style={{ fontSize: 20 }}>MY POSSE BOX SUBSCRIPTION</h2>
          <div className="show-checkbox">
            <label className="container">
              On
              <input
                className="color-check"
                type="checkbox"
                checked={checkBox1}
                onClick={() => {
                  setCheckBox1("checked");
                  setCheckBox2("");
                }}
              />
              <span className="checkmark"></span>
            </label>

            <label className="container">
              Cancel
              <input
                className="color-check"
                type="checkbox"
                checked={checkBox2}
                onClick={() => {
                  setCheckBox1("");
                  setCheckBox2("checked");
                  setIsOpen(true);
                  console.log("Open");
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="show-date-sub">
            <p className="date-sub-set">ACTIVE {dateSub}</p>
          </div>
          <div className="info-help">
            Need help? Customer Support at 877-276-5627 or email
            support@plunderdesign.com
          </div>
        </div>
      )}

      <div className="App">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setCheckBox1("checked");
            setCheckBox2("");
            setIsOpen(false);
          }}
          overlayClassName={{
            base: "overlay-base",
            afterOpen: "overlay-after",
            beforeClose: "overlay-before",
          }}
          className={{
            base: "content-base",
            afterOpen: "content-after",
            beforeClose: "content-before",
          }}
          closeTimeoutMS={500}
        >
          <div>
            <h2 className="wait-title">WAIT</h2>
            <p className="paragraph-text">
              Are you sure you want to cancel your subscription of this PosseBox
              for the amount of $29.00?
            </p>
          </div>
          <div className="center-buttons">
            <button
              className="btn-confirm"
              onClick={() => {
                
                setIsOpen(false);
              }}
            >
              CONFIRM
            </button>
            <button
              className="btn-cancel"
              onClick={() => {
                setCheckBox1("checked");
                setCheckBox2("");
                setIsOpen(false);
              }}
            >
              CANCEL
            </button>
          </div>
          <button
            className="close-modal"
            onClick={() => {
              setCheckBox1("checked");
              setCheckBox2("");
              setIsOpen(false);
            }}
          >
            <a className="text-close-modal">X</a>
          </button>
        </Modal>
      </div>
    </>
  );
}

export default PosseBoxSub;
