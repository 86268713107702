import React from "react";
import "./ButtonWithdrawAllEarning.css";

function ButtonWithdrawAllEarning(props) {
  const { className } = props;

  return (
    <a href="https://plunderdesign.com/store/search" target="_blank" >
      <div className={`button-withdraw-all-earning-2 ${className || ""}`}>
        <div className="shop-now new-avenir-heavy-normal-black-16px">SHOP NOW</div>
      </div>
    </a>
  );
}

export default ButtonWithdrawAllEarning;
