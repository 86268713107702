import React from "react";
import "./DueDate.css";

function DueDate() {
  return (
    <div className="due-date-3">
      <div className="x1000-1100-12 new-manrope-normal-eerie-black-12px">3pt</div>
    </div>
  );
}

export default DueDate;
