import React from "react";
import "./Dashboard1.css";

function Dashboard1(props) {
  const { dashboard1, className } = props;

  return (
    <div className={`new-dashboard-1-1 ${className || ""}`} style={{ backgroundImage: `url(${dashboard1})` }}>
    </div>
  );
}

export default Dashboard1;
