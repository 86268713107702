import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RecentActivity from "../NewRecentActivity";
import Group73 from "../NewGroup73";
import PointsProgressBar from "../NewPointsProgressBar";
import ButtonWithdrawAllEarning from "../NewButtonWithdrawAllEarning";
import ButtonWithdrawCouponEarning from "../NewButtonWithdrawCouponEarning";
import ButtonWithdrawExclusiveEarning from "../NewButtonWithdrawExclusiveEarning";
import Modal from "react-modal";

import Dashboard1 from "../NewDashboard1";
import RewardsReports from "../NewRewardsReports";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';


import "./DesktopHomeStylistDashboard.css";
// import "./styles.css"


import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBContainer, MDBRow, MDBCol
} from "mdb-react-ui-kit";

import CloseIcon from '@mui/icons-material/Close';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import SmsIcon from '@mui/icons-material/Sms';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import PinterestIcon from '@mui/icons-material/Pinterest';
import GoogleIcon from '@mui/icons-material/Google';

import axios from "axios";
import { color } from "@mui/system";

Modal.setAppElement("#app");

function DesktopHomeStylistDashboard(props) {
  let email = params.email;
  const [isLoading, setLoading] = useState();
  const [firstName, setFirstName] = useState();
  const [customerType, setCustomerType] = useState();
  const [open, setOpen] = useState();
  const [idUser, setIdUser] = useState();
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonusPointsT, setBonusPointsT] = useState(0);
  const [points, setPoints] = useState(0);
  const [lastsix, setLastSix] = useState();
  const [datos, setDatos] = useState([]);
  const [userName, setUserName] = useState("");
  const [copiado, setCopiado] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [items, setItems] = useState([]);
  const [itemsAux,setItemsAux] =useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [seeDetails, setSeeDetails] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [urlCheckOut, setUrlCheckOut] = useState("");
  const [cuponName, setCupoName] = useState("");
  const [nameCupon, setNameCupon] = useState("");
  const [cartItems, setCartItems] = useState([]);

  const [showURL, setShowURL] = useState("");

  
  const [elementsAdded, setElementsAdded] = useState([]);
  
  const [selectedItems, setSelectedItems] = useState([]);
  
  const [shortUrl,setshortUrl]=useState("");

  localStorage.setItem("cart-items-create-cart", cartItems);
  // pk_2Cnio4wESsLt27U4
  // urlCheckOut
  async function getShortUrl(uLong) {
    try {
      console.log("acortar con bitly");
      const url = 'https://api-ssl.bitly.com/v4/shorten';
      const params = {
        method: 'POST',
        headers: {

          'Authorization': 'Bearer aabaa3ff4e26283a171afd1b8b807db8bd312fa6',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "long_url": uLong, "domain": "bit.ly" })
      };
      const response = await fetch(url, params);
      const result = await response.json();
      console.log(result.link);
      setshortUrl(result.link);
      return true ;
    } catch (error) {
      console.log("error",error);
      return false;
    }
  }
  

  const {
    group252,
    activityLogs1,
    path641,
    iconStar,
    lifetimeMember,
    price,
    youAlwaysEarn10Rewards,
    group253,
    activityLogs2,
    group254,
    iconGift1,
    path642,
    iconGift2,
    x20Points,
    x30Points,
    x50Points,
    address1,
    address2,
    address3,
    address4,
    x75PointsGift,
    spanText1,
    spanText2,
    overlapGroup1,
    exclusiveItemAccess,
    number1,
    group249,
    overlapGroup2,
    x50OffCoupons,
    group248,
    number2,
    redeemInCart1,
    availablePoints,
    number3,
    group246,
    redeemInCart2,
    path51,
    path52,
    path53,
    path54,
    bonusRewards,
    forEvery200InPurchases,
    learnMore1,
    group239,
    percent,
    always10Per1Spent,
    onPersonalReferr,
    learnMore2,
    dashboard1,
    proposal,
    projects1,
    iconUser,
    projects2,
    group281,
    projects3,
    group292,
    projects4,
    group284,
    projects5,
    plunderBlackLogo250,
    title,
    welcome,
    activityLogs3,
    plunderdesignComXxxxxxx,
    dashboard2,
    recentActivityProps,
    pointsProgressBar1Props,
    buttonWithdrawAllEarningProps,
    pointsProgressBar2Props,
    dashboard1Props,
    group82Props,
  } = props;

  const emailLS = localStorage.getItem("correo_usuario");

  if (!email && emailLS) email = emailLS;

  const data = { email: email };
  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var code = "";

  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    code += chars.substring(randomNumber, randomNumber + 1);
  }

  const [itemsSelect,setitemsSelect]=useState([]);

  useEffect(() => {
    const publicToken = Ecwid.getAppPublicToken('plunder_design')
    setLoading(true);
    setOpen(true);
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${publicToken}`,
        "Content-Type": "application/json",
      },
    };

    fetch(`https://app.ecwid.com/api/v3/78338716/products`, options)
      .then((response) => response.json())
      .then((response) => {
        setItems(response["items"]);
        setItemsAux(response["items"]);
        setLoading(false);
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    const dateTest = new Date();
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer secret_shCfyghHTrrUnurc8LCWuT1Q9Sh5zQ8W",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: "50OFFCoupon " + dateTest,
        code: code,
        discountType: "PERCENT",
        status: "ACTIVE",
        discount: 50,
        launchDate: "2014-06-06 08:00:00 +0400",
        usesLimit: "SINGLE",
        applicationLimit: "REPEAT_CUSTOMER_ONLY",
        orderCount: 0,
        catalogLimit: {
          products: [itemSelected["id"]],
          categories: [135699793],
        },
      }),
    };

    fetch("https://app.ecwid.com/api/v3/78338716/discount_coupons", options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCupoName(response["code"]);
        setNameCupon("50OFFCoupon " + dateTest);
      })
      .catch((err) => console.error(err));
  }, [applyCoupon]);


  const [emailCustomer,setemailCustomer]=useState();

  const generateCart = () => {
    const cartItemsCurrently = localStorage
      .getItem("cart-items-create-cart")
      .split(",");
    
    const currentItems = cartItemsCurrently.map((productID) => {
      return {
        id: parseInt(productID),
        quantity: 1,
      };
    });

    var profileUser={
      email: emailCustomer
    }

    var cart = {
      gotoCheckout: true, // go to next checkout step right away (after 'Cart' page)
      products: currentItems,
      profile:profileUser // products to add to cart
    };

    cart = JSON.stringify(cart);
    cart = encodeURIComponent(cart);
    // Pasar el sponsor sponsor=UserName
    // setUrlCheckOut(`https://plunderdesign.com/shoppingcart/store#!/~/cart/create=${cart}`);

    getShortUrl(`https://plunderdesign.com/shoppingcart/store#!/~/cart/create=${cart}`);
  };

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch("https://deploywk.herokuapp.com/plunder-customer-dashboard/", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setFirstName(res[0]["firstName"]);
        setCustomerType(res[0]["customerType"]);
        setIdUser(res[0]["id"]);
        localStorage.setItem("correo_usuario", email);
        localStorage.setItem("infoUsuario", JSON.stringify(res[0]));
        localStorage.setItem("customerType", res[0]["customerType"]);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);

    fetch(
      `https://deploywk.herokuapp.com/plunder-customer-data/plunder/${email}/`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLastSix(response["lastsix"]["value"]);
        setDatos(response["lasttransactions"]);
        setUserName(response["username"]);
        setCheckCustomer(localStorage.getItem("customerType"));

        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        console.log(error);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  useEffect(() => {

    setLoading(true);
    setOpen(true);
    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"].length != 0) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "PlunderPoints") {
              setPoints(bonus["amount"] - bonus["released"]);
              setTotalPoints(bonus["amount"]);
            }
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"] - bonus["released"]);
              setBonusPointsT(bonus["amount"]);
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(Math.trunc(bonus["amount"]));
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setDiscountValue(Math.trunc(bonus["amount"]));
              setReleasedVaule(Math.trunc(bonus["released"]));
            }
          });
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((res) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT 2.0");
        setLoading(false);
        setOpen(false);
      });
  }, [idUser]);

  useEffect(() => {
    setLoading(true);
    fetch(`https://api.shrtco.de/v2/shorten?url=${urlCheckOut}`)
      .then((res) => res.json())
      .then((res) => {
        setShowURL(res.result.full_short_link);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [urlCheckOut]);

  // Setting differents classes depending of the numbers
  const finalPoints =
    points + bonusPoints - Math.trunc(points + bonusPoints)
      ? Math.trunc(points + bonusPoints) + 1
      : Math.trunc(points + bonusPoints);

  const classRewards = `new-number-2-${
    finalPoints.toString().length
  }-numbers new-avenir-medium-black-50px`;

  const offCouponds = discount - released;
  const classCouponds = `new-number-1-${
    offCouponds.toString().length
  }-numbers new-avenir-medium-black-50px`;

  const classItemValue = `new-number-${
    itemValue.toString().length
  }-numbers new-avenir-medium-black-50px`;


  function deleteItem(productID,ind){
    let listaSelected=localStorage.getItem("cart-items-create-cart");
    let listaSelected_=listaSelected.split(',');
    let indice=listaSelected_.indexOf(String(productID));
    if(indice>=0){
      listaSelected_.splice(indice,1);
      let newitemsSelect=itemsSelect.filter((item)=>{return item !== itemsSelect[ind] });
      let newElementsAdded=elementsAdded.filter((item)=>{return item !== productID});
      let newcartItems=cartItems.filter((item)=>{return item !== productID});
      let newselectedItems=selectedItems.filter((item)=>{return item !== productID});

      setitemsSelect(newitemsSelect);
      setElementsAdded(newElementsAdded);
      setCartItems(newcartItems);
      setSelectedItems(newselectedItems);
      localStorage.setItem("cart-items-create-cart",newElementsAdded);
    }else{
      console.log("no se encontró");
    }
  }

  const [showHamburgerV1, setShowHamburgerV1] = useState(true);
  const [estadoPersonalizeOrder,setestadoPersonalizeOrder]=useState(true);
  const [checkCustomer, setCheckCustomer] = useState("");
  const [estadoBackOffice,setestadoBackOffice]=useState(true);

  // const [redirect, setredirect]=useState(true);
  // const [estadoLogin,setEstadoLogin]=useState(false);

  // const login = () => {
  //   try {
  //     console.log("este es el email que se envia: ", email);
  //     const url = "https://authworkinglive.com/auth/login";
  //     const params = {
  //       method: "POST",
  //       body: JSON.stringify({
  //         "email-address": email,
  //         password: null,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "https://authworkinglive.com",
  //       },
  //       credentials: "include",
  //     };
  //     fetch(url, params)
  //       .then((res) => {
  //         if (!res.ok) {return setredirect(false)}else {return res.json()};
  //       })
  //       .then((res) => {
  //         console.log("valor login ", res);
  //         if(redirect){
  //           setEstadoLogin(true);
  //           window.open('https://authworkinglive.com/o/authorize/?response_type=code&code_challenge=D61lEcl9JrlRMXYXRjFVNdrgHQST7x6zELCliqb6Udw&client_id=YQrblASXUxYr19YepYxfRwj5ivdykp5FQVnFXVJs&redirect_uri=https://plunderdesign.myvoffice.com/callback/index.cfm','_blank');
  //         }else{
  //           console.log('No se pudo');
  //           setEstadoLogin(false);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     setEstadoLogin(false);
  //     return null;
  //   }
  // };

  // const linkto = ()=>{
  //   if(estadoLogin){
  //     console.log("ya esta iniciado sesion");
  //     window.open('https://authworkinglive.com/o/authorize/?response_type=code&code_challenge=D61lEcl9JrlRMXYXRjFVNdrgHQST7x6zELCliqb6Udw&client_id=YQrblASXUxYr19YepYxfRwj5ivdykp5FQVnFXVJs&redirect_uri=https://plunderdesign.myvoffice.com/callback/index.cfm','_blank');
  //   }else{
  //     console.log("Se va a ejecutar nuevamente el login");
  //     login();
  //   }
  // }

  const linkto = ()=>{
    window.open(`https://authworkinglive.com/auth/loginv3?email=${email.toLowerCase()}`,'_blank');
  }



  const [prod, setprod] = useState(null);
  const [contador, setcontador]=useState(1);
  const [datafilter,setdatafilter]=useState(null);
  const queryProd= e=>{setprod(e.target.value);console.log(e.target.value);}

  function filterProducts(){
    let rowArray = [];
    let query=prod.toUpperCase();
      items.forEach(item => {
        let isArray = false;
        for (let key in item) {
            let col = item[key];
            if(typeof col != 'object' && typeof col != 'boolean'&& typeof col !='number' && typeof col != 'undefined'){
                let cols = col.toUpperCase();
                if(cols.includes(query)){
                    isArray = true;
                    setcontador(contador+1);
                }
            }
        }
        if(isArray === true){
            rowArray.push(item.id);
        }
      });
    setdatafilter(rowArray);
    if(rowArray.length === 0){
      setcontador(0);
    }
    console.log(contador);
    return true;
  }

  useEffect(()=>{
    if(prod===null||prod===""){
      console.log("input vacio");
      setItems(itemsAux);
    }else{
      filterProducts();
    }
  },[prod]);

  

  



  return (
    <div className="new-container-center-horizontal ">
      <div className="new-desktop-home-stylist-dashboard new-screen">
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div className="new-overlap-group9">
            <div className="show-hamburger-menu-v1" onClick={() => { setShowHamburgerV1(!showHamburgerV1); }} >
              <a>
                <img
                  width={25}
                  height={25}
                  src="https://i.imgur.com/I8jdeMN.png"
                ></img>
              </a>
            </div>
            <div hidden={showHamburgerV1} className="new-sidebar1-res-v1">
              <div className="new-menus new-avenir-medium-black-18px">
                <div className="new-overlap-group-5">
                  <img
                    className="new-plunder-black-logo-250"
                    src={plunderBlackLogo250}
                  />
                  <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                    {title}
                  </h1>
                </div>
                <Link to="/desktop-dashboard" className="none-decoration">
                  <div className="new-x01 change-selected">
                    <div className="new-group-300">
                      <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                      <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                        {dashboard1}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/desktop-my-account" className="none-decoration">
                  <div className="new-group-83">
                    <div className="new-group-82">
                      <img className="new-icon-user" src={iconUser} />
                      <div className="new-projects-1 new-avenir-medium-black-18px">
                        {projects2}
                      </div>
                    </div>
                  </div>
                </Link>
                {
                  estadoPersonalizeOrder&&(
                    <a className="none-decoration">
                      <div className="new-x02" onClick={()=>linkto()}>
                        <div className="new-group-301">
                          <div
                            className="new-proposal"
                            style={{ backgroundImage: `url(${proposal})` }}
                          ></div>
                          <div className="new-projects">Personalize Order</div>
                        </div>
                      </div>
                    </a>
                  )
                }
              
              {
                email == "jamie@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }
              {
                email == "tylortest10@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }
              {
                email == "tylortest10@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }

                {customerType !== "customer" && (
                  <Link
                    to="/desktop-create-a-cart-stylist-only"
                    className="none-decoration"
                  >
                    <div className="new-group-84-1 set-selected">
                      <div className="new-group-82-1">
                        <div className="new-group-302">
                          <div
                            className="new-group-281"
                            style={{ backgroundImage: `url(${group281})` }}
                          ></div>
                          <div className="new-projects-2">{projects3}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
                {customerType == "stylist" && (
                  estadoBackOffice &&(
                    <a className="none-decoration">
                      <div className="new-group-2" onClick={()=>linkto()} >
                        <div className="new-group-82-2">
                          <div className="new-group-303">
                            <div
                              className="new-group-292"
                              style={{ backgroundImage: `url(${group292})` }}
                            ></div>
                            <div className="new-projects-3">{projects4}</div>
                          </div>
                        </div>
                      </div>
                    </a>
                  )
                )}
                <div className="new-group-2">
                  <div className="new-group-304">
                    <div
                      className="new-group-284"
                      style={{ backgroundImage: `url(${group284})` }}
                    ></div>
                    <div className="new-group-82-3">
                      <div className="new-projects-4">{projects5}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Menu */}
            <div className="new-sidebar">
              <div className="new-menus new-avenir-medium-black-18px">
                <div className="new-overlap-group-5">
                  <img
                    className="new-plunder-black-logo-250"
                    src={plunderBlackLogo250}
                  />
                  <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                    {title}
                  </h1>
                </div>
                <Link to="/desktop-dashboard" className="none-decoration">
                  <div className="new-x01 change-selected">
                    <div className="new-group-300">
                      <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                      <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                        {dashboard1}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link to="/desktop-my-account" className="none-decoration">
                  <div className="new-group-83">
                    <div className="new-group-82">
                      <img className="new-icon-user" src={iconUser} />
                      <div className="new-projects-1 new-avenir-medium-black-18px">
                        {projects2}
                      </div>
                    </div>
                  </div>
                </Link>
                {
                  estadoPersonalizeOrder&&(
                    <a className="none-decoration">
                      <div className="new-x02" onClick={()=>linkto()}>
                        <div className="new-group-301">
                          <div
                            className="new-proposal"
                            style={{ backgroundImage: `url(${proposal})` }}
                          ></div>
                          <div className="new-projects">Personalize Order</div>
                        </div>
                      </div>
                    </a>
                  )
                }
              
                {/* {customerType !== "customer" && (
                  <Link
                    to="/desktop-my-stylist-rewards-reports"
                    className="none-decoration"
                  >
                    <div className="new-group-84">
                      <RewardsReports
                        group85Props={group82Props.group85Props}
                      />
                    </div>
                  </Link>
                )} */}
                {
                  email == "jamie@plunderdesign.com" &&(
                    checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                      <Link
                        to="/desktop-my-stylist-rewards-reports"
                        className="none-decoration"
                      >
                        <div className="new-group-84">
                          <RewardsReports group85Props={group82Props.group85Props} />
                        </div>
                      </Link>
                    )
                  )
                }
                {
                email == "tylortest10@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }

                {customerType !== "customer" && (
                  <Link
                    to="/desktop-create-a-cart-stylist-only"
                    className="none-decoration"
                  >
                    <div className="new-group-84-1 set-selected">
                      <div className="new-group-82-1">
                        <div className="new-group-302">
                          <div
                            className="new-group-281"
                            style={{ backgroundImage: `url(${group281})` }}
                          ></div>
                          <div className="new-projects-2">{projects3}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
                {customerType == "stylist" && (
                  estadoBackOffice &&(
                    <a className="none-decoration">
                      <div className="new-group-2" onClick={()=>linkto()} >
                        <div className="new-group-82-2">
                          <div className="new-group-303">
                            <div
                              className="new-group-292"
                              style={{ backgroundImage: `url(${group292})` }}
                            ></div>
                            <div className="new-projects-3">{projects4}</div>
                          </div>
                        </div>
                      </div>
                    </a>
                  )
                )}
                <div className="new-group-2">
                  <div className="new-group-304">
                    <div
                      className="new-group-284"
                      style={{ backgroundImage: `url(${group284})` }}
                    ></div>
                    <div className="new-group-82-3">
                      <div className="new-projects-4">{projects5}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-right">
              <div className="new-welcome-v1">
                <div className="new-welcome-2 new-avenir-book-normal-black-18px index-plus-text">
                  {firstName}, here you can buy your{" "} <strong style={{ fontWeight: 600}}>favorite products</strong>
                </div>
              </div>
              <div className="new-background-left">
                <div style={{width:'100%', marginBottom:'10px', marginTop:'10px', marginLeft:'5px', marginRight:'5px', paddingRight:'15px'}}>
                  <TextField
                    label="Search"
                    id="txtSearch"
                    value={prod}
                    onChange={queryProd}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                    }}
                  />
                </div>
                {/* Products */}
                {seeDetails && (
                  <div className="modal-container-v1">
                    <div className="contenedorDetalle">
                      <div className="content-product-details-image">
                        <img
                          className="image-detail"
                          src={itemSelected["originalImageUrl"]}
                        ></img>
                      </div>
                      <div className="content-product-details-content">
                        <b className="change-font-product-cart">
                          {itemSelected["name"]}
                        </b>
                        <br />
                        <br />
                        <p className="change-font-product-cart">
                          SKU {itemSelected["sku"]}
                        </p>
                        <br />
                        <p className="change-font-product-cart">
                          {itemSelected["defaultDisplayedPriceFormatted"]}
                        </p>
                        <br />
                        <button
                          // className="button-apply-coupon"
                          className="button-back-coupon"
                          onClick={() => {
                            setSelectedItems([...selectedItems,itemSelected["id"]]);
                            setCartItems([...cartItems, itemSelected["id"]]);
                            localStorage.setItem("cart-items-create-cart",cartItems);

                            setElementsAdded([
                              ...elementsAdded,
                              itemSelected["id"],
                            ]);
                            setitemsSelect([
                              ...itemsSelect,
                              itemSelected,
                            ]);
                            setSeeDetails(false)

                          }}
                          disabled={selectedItems.includes(itemSelected["id"])}
                        >
                          {selectedItems.includes(itemSelected["id"])
                            ? "Added"
                            : "Add to Cart"}
                        </button>
                        <br />
                        {cuponName && (
                          <p className="product-details-show-letters">
                            Coupon has been created {cuponName}
                          </p>
                        )}
                        <br />
                        <p className="product-details-literal change-font-product-cart">
                          Product Details
                        </p>
                        <br />
                        <p className="product-details-show change-font-product-cart">
                          Multi-colored crystal encrusted bar set in gold on gold
                          chain.
                        </p>
                        <p className="product-details-show change-font-product-cart">
                          6.75" - 7.5" adj.
                        </p>
                        <p className="product-details-show change-font-product-cart">
                          Stainless steel.
                        </p>
                        <p className="product-details-show change-font-product-cart">
                          {" "}
                          Weight: 0.1 oz.
                        </p>
                        <br />
                        <br />
                        <button
                          className="button-back-coupon"
                          onClick={() => setSeeDetails(false)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {!seeDetails && (
                  <>
                    <div className="modal-container-v1 scroll-cards">
                      {/* {items.map((item) => {
                        if (item["id"] !== 476249324 && item["id"] !== 470024185){
                          if(item["enabled"]===true && item["media"]["images"].length>0){
                            return (
                              <>
                                <MDBCard
                                  className="card-product-modal"
                                  onClick={() => {
                                    setItemSelected(item);
                                    setSeeDetails(true);
                                    window.scroll(0, 0);
                                  }}
                                >
                                  <div style={{ maxWidth: "100%" }}>
                                    {
                                      <MDBCardImage
                                        src={item["thumbnailUrl"]}
                                        position="top"
                                        alt="..."
                                        style={{ maxWidth: "100%" }}
                                        className="image-header"
                                      />
                                      
                                    }
                                    
                                  </div>
                                  <MDBCardBody className="card-product-modal-body">
                                    <MDBCardTitle className="set-title-card">
                                      {item["name"]}
                                    </MDBCardTitle>
                                    <MDBCardText>
                                      <div className="content-check">
                                        <div className="set-size-price change-font-product-cart">
                                          {item["defaultDisplayedPriceFormatted"]}
                                        </div>
                                        <div id={'check'+item["id"]} className="set-size-icon">
                                          {
                                            elementsAdded.includes(item["id"]) && (
                                            <img
                                              className="check-icon-product"
                                              src="https://i.imgur.com/YuYBy3C.png"
                                              alt="Added"
                                            />
                                          )
                                          }
                                        </div>
                                      </div>
                                    </MDBCardText>
                                  </MDBCardBody>
                                </MDBCard>
                                
                              </>
                            );
                          }
                        }
                      })} */}
                      {
                        !prod &&(
                          items.map((item,index) => {
                            if (item["id"] !== 476249324 && item["id"] !== 470024185){
                              if(item["enabled"]===true && item["media"]["images"].length>0){
                                return (
                                  <>
                                    <MDBCard
                                      className="card-product-modal"
                                      onClick={() => {
                                        setItemSelected(item);
                                        setSeeDetails(true);
                                        window.scroll(0, 0);
                                      }}
                                    >
                                      <div style={{ maxWidth: "100%" }}>
                                        {
                                          <MDBCardImage
                                            src={item["thumbnailUrl"]}
                                            position="top"
                                            alt="..."
                                            style={{ maxWidth: "100%" }}
                                            className="image-header"
                                          />
                                          
                                        }
                                        
                                      </div>
                                      <MDBCardBody className="card-product-modal-body">
                                        <MDBCardTitle className="set-title-card">
                                          {item["name"]}
                                        </MDBCardTitle>
                                        <MDBCardText>
                                          <div className="content-check">
                                            <div className="set-size-price change-font-product-cart">
                                              {item["defaultDisplayedPriceFormatted"]}
                                            </div>
                                            <div id={'check'+item["id"]} className="set-size-icon">
                                              {
                                                elementsAdded.includes(item["id"]) && (
                                                <img
                                                  className="check-icon-product"
                                                  src="https://i.imgur.com/YuYBy3C.png"
                                                  alt="Added"
                                                />
                                              )
                                              }
                                            </div>
                                          </div>
                                        </MDBCardText>
                                      </MDBCardBody>
                                    </MDBCard>
                                    
                                  </>
                                );
                              }
                            }
                          })
                        )
                      }
                      {
                        prod &&(
                          items.map((item,index) => {
                            if (item["id"] !== 476249324 && item["id"] !== 470024185){
                              if(item["enabled"]===true && item["media"]["images"].length>0){
                                let idProd=item.id;
                                if(datafilter!=null){
                                  let index_r= datafilter.indexOf(idProd);
                                  if(index_r>=0){
                                    return (
                                      <>
                                        <MDBCard
                                          className="card-product-modal"
                                          onClick={() => {
                                            setItemSelected(item);
                                            setSeeDetails(true);
                                            window.scroll(0, 0);
                                          }}
                                        >
                                          <div style={{ maxWidth: "100%" }}>
                                            {
                                              <MDBCardImage
                                                src={item["thumbnailUrl"]}
                                                position="top"
                                                alt="..."
                                                style={{ maxWidth: "100%" }}
                                                className="image-header"
                                              />
                                              
                                            }
                                            
                                          </div>
                                          <MDBCardBody className="card-product-modal-body">
                                            <MDBCardTitle className="set-title-card">
                                              {item["name"]}
                                            </MDBCardTitle>
                                            <MDBCardText>
                                              <div className="content-check">
                                                <div className="set-size-price change-font-product-cart">
                                                  {item["defaultDisplayedPriceFormatted"]}
                                                </div>
                                                <div id={'check'+item["id"]} className="set-size-icon">
                                                  {
                                                    elementsAdded.includes(item["id"]) && (
                                                    <img
                                                      className="check-icon-product"
                                                      src="https://i.imgur.com/YuYBy3C.png"
                                                      alt="Added"
                                                    />
                                                  )
                                                  }
                                                </div>
                                              </div>
                                            </MDBCardText>
                                          </MDBCardBody>
                                        </MDBCard>
                                        
                                      </>
                                    );   
                                  }
                                }
                              }
                            }
                          })
                        )
                      }
                    </div>
                  </>
                )}
              </div>
              {/* Generate Cart */}
              <div className="new-background-right"> 
                <div style={{display:"flex", justifyContent:"center", marginTop:5}}>
                  <button
                    className={
                      "button-back-coupon generate-button" +
                      (cartItems ? "" : " disabledCart")
                    }
                    onClick={() => {
                      generateCart();
                      setIsOpen(true);
                    }}
                    disabled={!cartItems}
                    >
                    Generate Cart
                  </button>
                </div>
                <div className="productsSelect"  style={{marginTop:10,marginRight:5, display:"flex",  }}>                    
                  {
                    itemsSelect.length!==[] &&(
                      itemsSelect.map((element,index)=>(
                        <div  id={element["id"]} style={{marginLeft:5,marginBottom:10}} >
                          <Card key={element["id"]} sx={{ display: 'flex', width:'100%' }}>
                            <CardMedia
                              component="img"
                              sx={{ width: 200 }}
                              image={element["imageUrl"]}
                              alt="Live from space album cover"
                              
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'flex-end', marginLeft:'auto' }}>
                              <div style={{display:'flex', alignItems:'end', justifyContent:'end'}}>
                                <IconButton aria-label="Quit" onClick={()=>deleteItem(element["id"],index)}>
                                  <CloseIcon sx={{ height: 20, width: 20 }} />
                                </IconButton>
                              </div>
                              <CardContent sx={{ flex: '1 0 auto', display:'flex', flexDirection:'column', justifyContent:'flex-start' }} >
                                <div style={{display:'flex', justifyContent:'center', flexDirection:'column',alignItems:'center'}}>
                                  <div className="detailProd" >
                                    <b className="change-font-product-cart">
                                      {element["name"]}
                                    </b>
                                  </div>
                                  <div className="price-v1" >
                                    <p className="change-font-product-cart">
                                      {"$"+element["price"]}
                                    </p>
                                  </div>
                                </div>
                              </CardContent>
                              
                            </Box>
                            
                          </Card>
                        </div>
                      ))
                    )
                  }
                
                  
                </div>
              </div>
            </div>
            {/* Modal Compartir */}
            <div className="App">
              <Modal
                isOpen={modalIsOpen}
                style={{width:"100%"}}
                onRequestClose={() => setIsOpen(false)}
                overlayClassName={{
                  base: "overlay-base",
                  afterOpen: "overlay-after more-index-plus",
                  beforeClose: "overlay-before",
                }}
                className={{
                  base: "content-base",
                  afterOpen: "content-after-max content-after-max-check",
                  beforeClose: "content-before",
                }}
                closeTimeoutMS={500}
              >
                <div className="show-generate-cart">
                  <div className="show-generate-cart-title">
                    SHARE
                  </div>
                  <div className="buttons-generated">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="fb" sx={{ width: 65, height: 65,bgcolor:'#3b5998' }}>
                                <FacebookSharpIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Facebook
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`mailto:info@example.com?&subject=&cc=&bcc=&body=${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="email" sx={{ width: 65, height: 65,bgcolor:'#009688' }}>
                                <EmailIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Email
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`http://instagram.com/_u/USERNAME/${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="ig" sx={{ width: 65, height: 65,bgcolor:'#ff1744' }}>
                                <InstagramIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Instagram
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`sms:+1?body=${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#ff9100' }}>
                                <SmsIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  SMS
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://t.me/share?url=${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#2196f3' }}>
                                <TelegramIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Telegram
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://pinterest.com/pin/create/button/?url=${shortUrl}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#E00A0A' }}>
                                <PinterestIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Pinterest
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <div className="contenedorLink">
                    <input className="inputCopy"  value={shortUrl} ></input>
                    <button type="button" className="buttonCopy" onClick={() => {
                        navigator.clipboard
                          .writeText(shortUrl)
                          .then(() => {
                            setCopiado(true);
                            console.log("Text copied to clipboard...");
                          })
                          .catch((err) => {
                            setCopiado(false);
                            console.log("Something went wrong", err);
                          });
                      }}>{copiado ? "COPIED " : "COPY LINK"}</button>
                  </div>
                </div>
                <button
                  className="close-modal"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <a className="text-close-modal">X</a>
                </button>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default DesktopHomeStylistDashboard;
