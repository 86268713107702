import React from "react";
import "./Group85.css";

function RewardsIcon(props) {
  const { className } = props;

  return (
    <div className={`group-85 ${className || ""}`}>
      <div className="new-path-container-3">
        <img className="path-69-1" src="/img/new-path-69-10@1x.png" />
        <img className="path-70-1" src="/img/new-path-70-10@1x.png" />
      </div>
    </div>
  );
}

export default RewardsIcon;
