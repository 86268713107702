import React from "react";
import DateActual from "../NewDate";
import "./Group103.css";

function Group103(props) {
  const { className, dateProps, data, lastsix, customerType, dataAffiliate } =
    props;
  // console.log("data que viene como prop del ultimo componente",data);
  let valueData=parseFloat(data.value);
  valueData=valueData.toFixed(1);
  const dateTrans = data.date.split("T");
  const dateFix = dateTrans[0] + " " + " " + dateTrans[1].slice(0, 5);

  const valueBeforePoint = data["value"];

  const checkUserType =
    data["emailAddress"] === localStorage.getItem("correo_usuario");

  let points=data["finalValue"];

  // if (dataAffiliate) {
  //   const dateAffiliate = dataAffiliate["affiliateUpgrade"].replace("Z", "");
  //   points =
  //     ((new Date(data["date"]) - new Date(dateAffiliate) !== -1000 && new Date(data["date"]) - new Date(dateAffiliate) !== 0) && (new Date(data["date"]) < new Date(dateAffiliate)))
  //       ? valueBeforePoint * 0.05
  //       : valueBeforePoint * 0.1;
  // } else {
  //   if (checkUserType) {
  //     points =
  //       customerType !== "customer"
  //         ? valueBeforePoint * 0.1
  //         : valueBeforePoint * 0.05;
  //   } else {
  //     if (customerType !== "customer") {
  //       points = valueBeforePoint * 0.1;
  //     } else {
  //       points =
  //         lastsix >= 200 ? valueBeforePoint * 0.1 : valueBeforePoint * 0.05;
  //     }
  //   }
  // }

  return (
    <div className={`group-103 ${className || ""}`}>
      <div className="group-100">
        <img className="new-icon-user-1" src="/img/new-icon-10@1x.png" />
      </div>
      <div className="new-overlap-group1-3">
        <div className="due-date-res">
          <div className="point-style-set new-manrope-normal-eerie-black-12px">
            {Math.trunc(points)} pt
            {/* {points} pt */}
          </div>
        </div>
        <div className="group-102">
          <div className="new-overlap-group-12">
            <DateActual className={dateProps.className} date={dateFix} />
            <div className="meeting-with-client-3 new-avenir-black-eerie-black-16px">
              {data.firstName} {data.lastName}
            </div>
          </div>
          <div className="x1000-1100-7 style-v2">
            Type: {customerType} ${valueData}
          </div>
        </div>
      </div>
    </div>
  );
}

const compareDates = (affiliateDate, transactionDate) => {
  const affiliate = new Date(affiliateDate);
  const transaction = new Date(transactionDate);

  return transaction < affiliate ? true : false;
};

export default Group103;
