import React from "react";
import "./Group40.css";

function Group40(props) {
  const { className } = props;

  return (
    <div className={`group-40 ${className || ""}`}>
      <img className="path-40" src="/img/path-40-1@1x.png" />
      <div className="consectetur-adipiscing manrope-bold-eerie-black-20px">Log Out</div>
    </div>
  );
}

export default Group40;
