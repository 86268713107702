import React, { useEffect, useState } from "react";
import "./ButtonWithdrawAllEarning2.css";
import Modal from "react-modal";
import "../Group68/Group68.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";

Modal.setAppElement("#app");

function ButtonWithdrawAllEarning2(props) {
  const { className } = props;

  const [open, setOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [items, setItems] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [seeDetails, setSeeDetails] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [urlCheckOut, setUrlCheckOut] = useState("");
  const [cuponName, setCupoName] = useState("");
  const [nameCupon, setNameCupon] = useState("");
  const [cartItems, setCartItems] = useState([]);

  var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var code = "";

  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    code += chars.substring(randomNumber, randomNumber + 1);
  }

  const [isLoading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    fetch(`https://deploywk.herokuapp.com/plunder-catalog/`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setItems(response["items"]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        console.log(error);
        setLoading(false);
        window.location.replace("/modal-disable");
      });
  }, [modalIsOpen]);

  /*  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://my.ecwid.com/api/oauth/token/78338716?client_id=custom-app-52424037-2&client_secret=L6B5ZJBu8Vm0PwiHk5bY3huFkUS5SapG&grant_type=authorization_code",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setToken(result);
      })
      .catch((error) => console.log("error", error));
  }, []); */

  useEffect(() => {
    const dateTest = new Date();
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer secret_shCfyghHTrrUnurc8LCWuT1Q9Sh5zQ8W",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: "50OFFCoupon " + dateTest,
        code: code,
        discountType: "PERCENT",
        status: "ACTIVE",
        discount: 50,
        launchDate: "2014-06-06 08:00:00 +0400",
        usesLimit: "SINGLE",
        applicationLimit: "REPEAT_CUSTOMER_ONLY",
        orderCount: 0,
        catalogLimit: {
          products: [itemSelected["id"]],
          categories: [135699793],
        },
      }),
    };

    fetch("https://app.ecwid.com/api/v3/78338716/discount_coupons", options)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setCupoName(response["code"]);
        setNameCupon("50OFFCoupon " + dateTest);
      })
      .catch((err) => console.error(err));
  }, [applyCoupon]);

  function getURLCheckout () {
    var cartItems = [
      {
        id: itemSelected["id"], // ID of the product in Ecwid
        quantity: 1,
      },
    ];

    var cart = {
      gotoCheckout: true, // go to next checkout step right away (after 'Cart' page)
      products: cartItems, // products to add to cart
      couponName: nameCupon,
    };

    cart = JSON.stringify(cart);
    cart = encodeURIComponent(cart);

    console.log(cart);

    setUrlCheckOut(`https://plunderdesign.com/store#!/~/cart/create=${cart}`);
  };

  return (
    <>
      <div className={`button-withdraw-all-earning-6 ${className || ""}`}>
        <div
          className="shop-now-1 avenir-medium-black-14px"
          onClick={() => setIsOpen(true)}
        >
          SHOP NOW
        </div>
      </div>
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      `{!isLoading && (
        <div className="App">
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            overlayClassName={{
              base: "overlay-base",
              afterOpen: "overlay-after",
              beforeClose: "overlay-before",
            }}
            className={{
              base: "content-base",
              afterOpen: "content-after-max",
              beforeClose: "content-before",
            }}
            closeTimeoutMS={500}
          >
            {/* <div
            class="ecsp ecsp-SingleProduct-v2 ecsp-Product ec-Product-470026960"
            itemtype="http://schema.org/Product"
            data-single-product-id="470026960"
          >
            <div
              class="ecsp-title"
              itemprop="name"
              style="display: none"
              content="Aaliyah Necklace"
            ></div>
            <div customprop="addtobag"></div>

          </div> */}

            {seeDetails && (
              <div className="content-product-details">
                <div className="content-product-details-image">
                  <img
                    className="image-detail"
                    src={itemSelected["originalImageUrl"]}
                  ></img>
                </div>
                <div className="content-product-details-content">
                  <b>{itemSelected["name"]}</b>
                  <br />
                  <br />
                  <p>SKU {itemSelected["sku"]}</p>
                  <br />
                  <p>{itemSelected["defaultDisplayedPriceFormatted"]}</p>
                  <br />
                  <button
                    className="button-apply-coupon"
                    onClick={() => {
                      setShowCheckout(true);
                    }}
                  >
                    Add to bag
                  </button>
                  <br />
                  {cuponName && (
                    <p className="product-details-show-letters">
                      Coupon has been created {cuponName}
                    </p>
                  )}
                  <br />
                  <p className="product-details-literal">Product Details</p>
                  <br />
                  <p className="product-details-show">
                    Multi-colored crystal encrusted bar set in gold on gold
                    chain.
                  </p>
                  <p className="product-details-show">6.75" - 7.5" adj.</p>
                  <p className="product-details-show">Stainless steel.</p>
                  <p className="product-details-show"> Weight: 0.1 oz.</p>
                  <br />
                  <br />
                  <button
                    className="button-back-coupon"
                    onClick={() => setSeeDetails(false)}
                  >
                    Back
                  </button>
                  {showCheckout && (
                    <>
                      <br />
                      <br />
                      <a href={urlCheckOut} target="_blank">
                        <button
                          className="button-back-coupon"
                          onClick={() => {
                            getURLCheckout();
                          }}
                        >
                          Go To Checkout
                        </button>
                      </a>
                    </>
                  )}
                </div>
              </div>
            )}
            {!seeDetails && (
              <>
                <div className="title-modal title-modal-v2-2">
                EXCLUSIVE SHOP
                </div>
                {/* <div className="input-container">
                  <input
                    type="text"
                    placeholder="Search products"
                    className="serchProducts"
                  />
                </div> */}
                <div className="modal-container">
                  {items.map((item) => {
                    return (
                      <>
                        <MDBCard
                          className="card-product-modal"
                          onClick={() => {
                            setItemSelected(item);
                            setSeeDetails(true);
                          }}
                        >
                          <div style={{ maxWidth: "100%" }}>
                            <MDBCardImage
                              src={item["thumbnailUrl"]}
                              position="top"
                              alt="..."
                              style={{ maxWidth: "100%" }}
                              className="image-header"
                            />
                          </div>
                          <MDBCardBody className="card-product-modal-body">
                            <MDBCardTitle>{item["name"]}</MDBCardTitle>
                            <MDBCardText>
                              {item["defaultDisplayedPriceFormatted"]}
                            </MDBCardText>
                          </MDBCardBody>
                        </MDBCard>
                      </>
                    );
                  })}
                </div>
              </>
            )}
            <button
              className="close-modal"
              onClick={() => {
                setSeeDetails(false);
                setIsOpen(false);
              }}
            >
              <a className="text-close-modal">X</a>
            </button>
          </Modal>
        </div>
      )}`
    </>
  );
}

export default ButtonWithdrawAllEarning2;
