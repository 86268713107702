import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import Searchbox from "../Searchbox";
import Group2 from "../Group2";
import Profile from "../Profile";
import Dashboard1 from "../Dashboard1";
import Group82 from "../Group82";
import Group822 from "../Group822";
import Group112 from "../Group112";
import "./DesktopMyConnectionsEcwidSocialConn.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function DesktopMyConnectionsEcwidSocialConn(props) {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [urlsso, setUrlsso] = useState("");
  const [infoUsuario, setInfoUsuario] = useState({});
  const [instagram, setInstagram] = useState(false);
  const [facebook, setFacebook] = useState(false);
  
  function fbPanel(){
    setInstagram(false);
    setFacebook(true);
    $("#ecwid-frame")[0].contentWindow.postMessage(
      JSON.stringify({
        ecwidAppNs: "custom-app-52424037-2",
        method: "openPage",
        data: "fb-shops",
      }),
      "*"
    );
  }
  function igPanel(){
    setInstagram(true);
    setFacebook(false);
    $("#ecwid-frame")[0].contentWindow.postMessage(
      JSON.stringify({
        ecwidAppNs: "custom-app-52424037-2",
        method: "openPage",
        data: "instagram",
      }),
      "*"
    );
  }

  useEffect(() => {
    setOpen(true);
    setLoading(true);
    setInfoUsuario(JSON.parse(localStorage.getItem("infoUsuario")));
    fetch("https://deploywk.herokuapp.com/getstore/75788344/", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((response) => {
        setUrlsso(response["urlsso"]);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        setLoading(false);
        setOpen(false);
      });
    fbPanel();
  }, []);

  const {
    desktopMyConnectionsEcwidSocialConn,
    icon,
    dashboard,
    proposal,
    projects,
    overlapGroup1,
    learnMore,
    overlapGroup2,
    yourImageHere1,
    sleepangle1,
    overlapGroup,
    explore,
    ongoing1,
    justIn,
    overlapGroup3,
    yourImageHere2,
    sleepangle2,
    overlapGroup4,
    subscribe,
    posseBox,
    ongoing2,
    myConnections,
    dashboard1Props,
    group82Props,
    group822Props,
  } = props;
  
  return (
    <div className="container-center-horizontal">
      <div
        className="desktop-my-connections-ecwid-social-connect-dashboard screen"
        style={{
          backgroundImage: `url(${desktopMyConnectionsEcwidSocialConn})`,
        }}
      >
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div className="overlap-group6-2">
            <div className="lateral">
              <div className="group-container-2">
                <div className="overlap-group4-5">
                  <div className="footer-1"></div>
                  <div className="menus-1">
                    <Link to="/desktop-dashboard">
                      <div className="group-83">
                        <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                        <div className="dashboard-4 avenir-medium-cod-gray-18px">
                          {dashboard}
                        </div>
                      </div>
                    </Link>
                    {/* <Link to="/desktop-my-connections-ecwid-social-connect-dashboard">
                      <div className="x02-1">
                        <div
                          className="proposal-1"
                          style={{ backgroundImage: `url(${proposal})` }}
                        ></div>
                        <div className="projects-11 avenir-medium-black-18px">
                          {projects}
                        </div>
                      </div>
                    </Link> */}
                    {/* <div
                      id="facebook-panel"
                      className={"x01-4" + facebook ? "facebook-panel" : ""}
                      onClick={() => {
                        fbPanel()
                      }}
                    >
                      <img
                        src="https://img.icons8.com/material-sharp/344/facebook-new.png"
                        className="facebook-image"
                      ></img>
                      <div className="dashboard-4 avenir-medium-cod-gray-18px facebook-text">
                        Facebook
                      </div>
                    </div>
                    <div
                      className={"x01-4" + instagram ? "instagram-panel" : ""}
                      onClick={() => {
                        igPanel()
                      }}
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/1384/1384015.png"
                        className="instagram-image"
                      ></img>
                      <div className="dashboard-4 avenir-medium-cod-gray-18px instagram-text">
                        Instagram
                      </div>
                    </div> */}
                    <Link to="/desktop-my-account">
                      <div className="group-83">
                        <Group82 iconUser={group82Props.iconUser} />
                      </div>
                    </Link>
                  </div>
                </div>
                <Group112 />
              </div>
            </div>
            <div className="my-connections montserrat-bold-black-44px">
              <iframe 
                id="ecwid-frame"
                src={urlsso}
                width="100%"
                height={1079.95}
                className="iframe-control-panel"
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

$("#facebook-panel").click(function () {
  $("#facebook-panel").toggleClass("facebook-panel");
});

export default DesktopMyConnectionsEcwidSocialConn;
