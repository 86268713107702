import React from "react";
import "./DueDate2.css";

function DueDate2() {
  return (
    <div className="due-date-4">
      <div className="x1000-1100-11 manrope-normal-eerie-black-12px">1 pt</div>
    </div>
  );
}

export default DueDate2;
