import React from "react";
import "./Group112.css";

function Group112() {
  return (
    <div class="new-group-112">
      <div class="new-header-1">
        <div class="new-group-268">
          <div class="new-overlap-group-5">
            <img
              class="new-plunder-black-logo-250"
              src="/img/new-plunder-black-logo-250-1@1x.png"
            />
            <h1 class="new-title new-brittanysignature-regular-normal-geyser-48px">
              perks
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Group112;
