import React from "react";
import "./PointsProgressBar.css";

function PointsProgressBar(props) {
  const { children, className } = props;

  return (
    <div className={`points-progress-bar ${className || ""}`}>
      <div className="new-activity-logs-4 new-avenir-heavy-normal-white-20px">{children}</div>
    </div>
  );
}

export default PointsProgressBar;
