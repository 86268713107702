import React from "react";
import "./Group73.css";

function Group73(props) {
  const { totalPoints, bonusPoints, lastsix } = props;
  const valueBonus = Math.round(
    200 * ((lastsix / 200 ) - Math.trunc(lastsix / 200))
  );

  return (
    <div className="group-73">
      <div className="overlap-group2-3">
        <div className="new-overlap-group1-6">
          <progress
            className="new-rectangle-32 new-border-2px-dove-gray new-barra-2"
            max={200}
            value={valueBonus}
          ></progress>
          {/* <div className="new-rectangle-31-3 new-border-2px-dove-gray"></div>
          <div className="new-rectangle-32-3 new-border-2px-dove-gray"></div> */}
          <img className="new-path-64-2" src="/img/new-path-64-1@1x.png" />
          <div className="new-path-container-1">
            <img className="new-path-51-1" src="/img/new-path-51-2@1x.png" />
            <img className="new-path-52-1" src="/img/new-path-52-2@1x.png" />
            <img className="new-path-53-1" src="/img/new-path-53-2@1x.png" />
            <img className="new-path-54-1" src="/img/new-path-54-2@1x.png" />
          </div>
        </div>
        <div className="new-price-1 new-avenir-heavy-normal-white-24px">
          ${valueBonus}
        </div>
        <div className="new-price-2 new-avenir-heavy-normal-sonic-silver-16px">
          $200
        </div>
        <p className="x25-more-to-10-bonus new-avenir-medium-sonic-silver-14px">
          <span className="new-avenir-heavy-normal-sonic-silver-14px">
            ${200 - valueBonus}
          </span>
          <span className="new-avenir-book-normal-sonic-silver-14px">
            &nbsp;
          </span>
          <span className="new-avenir-medium-sonic-silver-14px-2">
            more to 10 bonus points, 50% off item, 1 exclusive
          </span>
        </p>
      </div>
      <div className="new-activity-logs-2 new-avenir-heavy-normal-eerie-black-20px">
        bonus rewards
      </div>
    </div>
  );
}

export default Group73;
