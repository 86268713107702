import React from "react";
import Date from "../Date";
import "./Group103.css";

function Group103(props) {
  const { className, dateProps, data } = props;
  const dateTrans = data.date.split("T");
  const dateFix =  dateTrans[0] + " " + " " + dateTrans[1].slice(0, 5);

  const customerType = data["customerType"];
  const valueBeforePoint = data["value"];
  let points;
  if (customerType === "customer") {
    points = valueBeforePoint * 0.05
  } else {
    points = valueBeforePoint * 0.1
  }
  return (
    <div className={`group-103 ${className || ""}`}>
      <div className="group-100">
        <img className="icon-user-4" src="/img/icon-2@1x.png" />
      </div>
      <div className="overlap-group1-6">
        <div className="due-date">
          <div className="x1000-1100-4 manrope-normal-eerie-black-12px">
            {((points) - Math.trunc(points)) ? Math.trunc(points) + 1: Math.trunc(points)} pt
          </div>
        </div>
        <div className="group-102">
          <div className="overlap-group-26">
            <Date className={dateProps.className} date={dateFix} />
            <div className="meeting-with-client-2 avenir-black-eerie-black-16px">
              {data.firstName} {data.lastName}
            </div>
          </div>
          <div className="x1000-1100-5 manrope-extra-bold-bizarre-10px">
            Type: {customerType} ${data.value}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Group103;
