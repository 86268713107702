import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Dashboard1 from "../Dashboard1";
import "./DesktopMyAccount.css";
import "./style.css";
import PosseBoxSub from "../Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import RewardsReports from "../NewRewardsReports";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';




// import modals de mui
import Slide from '@mui/material/Slide';
// import { LoadingButton } from '@mui/material';

// Modal Cancel Sub

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DesktopMyAccount(props) {
  let email = params.email;
  const [isLoading, setLoading] = useState();
  const [firstName, setFirstName] = useState();
  const [idUser, setIdUser] = useState();

  const [fullName, setFullName] = useState();
  const [customerType, setCustomerType] = useState();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [sponsor, setSponsor] = useState();
  const [enrollDate, setEnrollDate] = useState();
  const [sponsorId, setSponsorId] = useState();
  const [phone, setPhone] = useState();
  const [dateSub, setDateSub] = useState("");

  const [showHamburger, setShowHamburger] = useState(true);

  const {
    superAdmin1,
    dashboard1,
    levelStatusCusto,
    dashboard2,
    plunderdesignComWeb_Alias,
    iconCall,
    email21,
    superAdmin2,
    superAdmin3,
    icon,
    dashboard3,
    proposal,
    projects,
    overlapGroup1,
    learnMore,
    overlapGroup2,
    yourImageHere1,
    sleepangle1,
    overlapGroup,
    explore,
    ongoing1,
    justIn,
    overlapGroup3,
    yourImageHere2,
    sleepangle2,
    overlapGroup4,
    subscribe,
    posseBox,
    ongoing2,
    myAccount,
    dashboard1Props,
    group82Props,
    group822Props,
    group292,
    projects4,
    group284
  } = props;
  const emailLS = localStorage.getItem("correo_usuario");

  if (!email && emailLS) email = emailLS;
  const data = { email: email };

  useEffect(() => {
    setLoading(true); 
    setOpen(!open);
    fetch("https://deploywk.herokuapp.com/plunder-customer-dashboard/", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem("correo_usuario", email);
        localStorage.setItem("infoUsuario", JSON.stringify(res[0]));
        setEmailUser(email);
        setLoading(false);
        setOpen(false);
        setFirstName(res[0]["firstName"]);
        setname(res[0]["firstName"]);
        setCustomerType(res[0]["customerType"]);
        setIdUser(res[0]["id"]);
        setFullName(res[0]["fullName"]);
        setStatus(res[0]["status"]);
        setSponsor(res[0]["companyName"]);
        setEnrollDate(res[0]["enrollDate"]);
        setSponsorId(res[0]["id"]);
        setPhone(res[0]["phoneNumbers"][0]["number"]);
        setPhoneUser(res[0]["phoneNumbers"][0]["number"]);
      })
      .catch((res) => {
        console.log(res);
        // setLoading(false);
        // setOpen(false);
      });
  }, []);

  const [susbs,setsusbs]=useState(0);
  const [userName,setUserName]=useState("");
  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(
      `https://deploywk.herokuapp.com/plunder-customer-data/plunder/${email}/`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("data prueba email: ",response);
        if (JSON.stringify(response["subscriptions"])!='{}'){
          setsusbs(parseInt(response["subscriptions"]["months"]));
        }
        setUserName(response["username"]);
        console.log("user name de account: ",response["username"]);
        console.log("subs: ",response["subscriptions"]);

        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        setLoading(false);
        setOpen(false);
      });
  }, []);

  const [estadoBackOffice,setestadoBackOffice]=useState(true);
  const [estadoPersonalizeOrder,setestadoPersonalizeOrder]=useState(true);

  // ACTUALIZAR NAME
  const [name,setname]=useState("");
  const [msj,setmsj]=useState("Are you sure you want to update the user name?");

  const btnUpdateName=()=>{
    updateUserData();
  }

  const saveUpdateName=()=>{
    setUserName(newName);
    updateUserData(newName);
  }


  const [stateUpName,setstateUpdateName]=useState(false);
  const [stateModalError,setstateModalError]=useState(false);
  const [stateMsjCorrect,setstateMsjCorrect]=useState(false);
  const [newName,setnewName]=useState(null);


  const hideModalError=()=>{
    setstateModalError(false);
  }
  const hidemsjCorrectUpName=()=>{
    setstateMsjCorrect(false);
  }

  const updateUserData = () => {
    const url = "https://deploywk.herokuapp.com/plunder-customer-update/";
    const params = {
      method: "POST",
      body: JSON.stringify({
        "comp":"PLUNDER",
        "nodeid": idUser,
        "fields":{"username": userName}
      }),
      headers: {
        "Content-Type": "application/json",
        },
    };
    fetch(url, params)
      .then(response => response.json())
      .then((res) => {
        console.log("estado actualización: ", res);
        if(res.response=="Customer Updated"){
          //setmsj("The process has finished successfully.");
          setmsj("Are you sure you want to update the user name?");
          setopenModalName(false);
          setstateUpdateName(true);
          setstateMsjCorrect(true);
          setstateModalError(false);

        }else{
          if(res.response=="Username Already Exists"){
            setstateModalError(true);
            setnewName(userName);
            setopenModalName(false);
            setstateMsjCorrect(false);
            setmsj("Are you sure you want to update the user name?");
          }else{
            setmsj("Something went wrong. Try again.");
            setstateUpdateName(true);
          }
        }
      });
  }

  const [openModalName, setopenModalName] = useState(false);
  const [openModalAvatar, setopenModalAvatar] = useState(false);
  const [avatarIn,setavatarIn]=useState("/img/default.jpg");


  const handleClickOpenModalName = () => {
    setopenModalName(true);
  };
  const handleClickOpenModalAvatar = () => {
    setopenModalAvatar(true);
  };

  const handleCloseModalName = () => {
    setopenModalName(false);
    setmsj("Are you sure you want to update the user name?");
    setstateUpdateName(false);

  };
  const handleCloseModalAvatar = () => {
    setopenModalAvatar(false);
    setavatarIn("/img/default.jpg");
  };

  const changeAvatar=(e)=>{
    const reader = new FileReader();
    reader.onload=()=>{
      if(reader.readyState===2){
        setavatarIn(reader.result);
      }
    }
    reader.readAsDataURL(e.target.files[0]);
  }


  const linkto = ()=>{
    window.open(`https://authworkinglive.com/auth/loginv3?email=${email.toLowerCase()}`,'_blank');
  }


  window.addEventListener('message', function (event) {
    if (event.data == "LogoutDashboard") {   
      console.log(event);
    }
  });

  const [phoneUser, setPhoneUser]=useState();
  const [emailUser, setEmailUser]=useState();
  const [addressUser,setAddressUser]=useState();

  

  return (
    <>
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {!isLoading && (
        <div className="new-overlap-group9-res">
          <div
            className="show-hamburger-menu"
            onClick={() => {
              setShowHamburger(!showHamburger);
            }}
          >
            <a>
              <img
                width={25}
                height={25}
                src="https://i.imgur.com/I8jdeMN.png"
              ></img>
            </a>
          </div>
          <div hidden={showHamburger} className="new-sidebar1-res">
            <div className="new-group-112-res">
              <div className="">
                <div className="new-group-268">
                  <div className="new-overlap-group-5">
                    <img
                      className="new-plunder-black-logo-250"
                      src="/img/new-plunder-black-logo-250-1@1x.png"
                    />
                    <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                      perks
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-menus-res new-avenir-medium-black-18px">
              <Link
                to="/desktop-dashboard"
                className="none-decoration"
              >
                <div className="new-x01 without-background">
                  <div className="new-group-300">
                    <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                    <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                      Dashboard
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/desktop-my-account" className="none-decoration">
                <div className="new-group-83 set-background-main">
                  <div className="new-group-82">
                    <img
                      className="new-icon-user"
                      src="/img/new-icon-14@1x.png"
                    />
                    <div className="new-projects-1 new-avenir-medium-black-18px">
                      My Account
                    </div>
                  </div>
                </div>
              </Link>
              {
                estadoPersonalizeOrder&&(
                  <a className="none-decoration">
                    <div className="new-x02" onClick={()=>linkto()}>
                      <div className="new-group-301">
                        <div
                          className="new-proposal"
                          style={{ backgroundImage: `url(${proposal})` }}
                        ></div>
                        <div className="new-projects">Personalize Order</div>
                      </div>
                    </div>
                  </a>
                )
              }
              {
                email == "jamie@plunderdesign.com" &&(
                  customerType !== "customer" && customerType !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }
              {
                email == "tylortest10@plunderdesign.com" &&(
                  customerType !== "customer" && customerType !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }

              {customerType !== "customer" && (
                <Link
                  to="/desktop-create-a-cart-stylist-only"
                  className="none-decoration"
                >
                  <div className="new-group-84-1">
                    <div className="new-group-82-1">
                      <div className="new-group-302">
                        <div
                          className="new-group-281"
                          style={{
                            backgroundImage: `url("/img/new-group-280-1@1x.png")`,
                          }}
                        ></div>
                        <div className="new-projects-2">Create a Cart</div>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
              {customerType == "stylist" && (
                estadoBackOffice &&(
                  <a className="none-decoration">
                    <div className="new-group-2" onClick={()=>linkto()} >
                      <div className="new-group-82-2">
                        <div className="new-group-303">
                          <div
                            className="new-group-292"
                            style={{ backgroundImage: `url(${group292})` }}
                          ></div>
                          <div className="new-projects-3">{projects4}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              )}
              <div className="new-group-2" onClick={()=>{top.postMessage("LogoutDashboard", '*');}}>
                <div className="new-group-304">
                  <div
                    className="new-group-284"
                    style={{ backgroundImage: `url(${group284})` }}
                    // este es el logout
                  ></div>
                  <div className="new-group-82-3">
                    <div className="new-projects-4">Log Out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-my-account">
            <div className="profile-box">
              <div className="group-127">
                <div className="group-20">
                  <img className="add-friend-2" src="/img/add-friend-2-2@1x.png" />
                </div>
                <div className="super-admin-6-v2 complemento-admin" onClick={handleClickOpenModalAvatar}>
                  {superAdmin1}
                </div>
                {/* <Dialog
                  open={openModalAvatar}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleCloseModalAvatar}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>
                    <div className="set-font-family-res">
                      <h2 style={{ fontSize: 20 }}>CHANGE AVATAR</h2>
                    </div>
                    </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <div className="set-font-family-res">
                        <div className="newAvatar">
                          <img src={avatarIn} alt="avatarSelect" />
                        </div>
                      </div>
                      <input type="file" accept="image/*" className="inputImage"  onChange={changeAvatar} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button 
                      variant="outlined"
                      onClick={handleCloseModalAvatar} 
                      sx={{backgroundColor:"#000",color:"#fff",'&:hover':{backgroundColor:"#808080",color:"#fff",borderColor:"#808080"}}}
                    >CANCEL</Button>
                    <LoadingButton
                      loading={false}
                      loadingPosition="ststartIconart"
                      sizeLarge
                      variant="contained"
                      sx={{backgroundColor:"#f2dedb",color:"#000",'&:hover':{backgroundColor:"#f2dedb",color:"#000"}}}
                    >
                      ACCEPT
                    </LoadingButton>
                  </DialogActions>
                </Dialog> */}
              </div>
              {isLoading && (
                <div>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              )}
              {
                susbs>0 ? (<PosseBoxSub dateSub={susbs} />):(<h1 className="user-no-sub">
                The user has no active subscriptions
              </h1>)
              }
              
            </div>
            <div className="group-23">
              <div className="overlap-group1-15">
                <div className="big-title">
                  <div className="dashboard-5 montserrat-bold-eerie-black-34px">
                    {fullName}
                  </div>
                  <div className="level-status-custo manrope-normal-quick-silver-18px">
                    {status} {customerType}
                    <br />
                    Sponsor Name: {sponsor} {enrollDate}
                    <br />
                    Sponsor ID: {sponsorId}
                  </div>
                  <div className="phone manrope-normal-quick-silver-18px">
                    <div className="input-addon">
                      <div className="input-addon__addon input-addon__addon--prepended">
                        <img style={{width:"30px",height:"30px"}} src="https://cdn-icons-png.flaticon.com/512/3059/3059502.png" alt="" />
                      </div>
                      <input className="inputData input-addon__input" type="text" value={phoneUser} onChange={(evet)=>setPhoneUser(evet.target.value)}/>
                      <div className="input-addon__addon input-addon__addon--appended">
                        <button className="editData" type="button">EDIT</button>
                      </div>
                    </div>
                  </div>
                  <div className="phone manrope-normal-quick-silver-18px">
                    <div className="input-addon">
                      <div className="input-addon__addon input-addon__addon--prepended">
                        <img style={{width:"30px",height:"30px"}} src="https://cdn-icons-png.flaticon.com/512/2099/2099199.png" alt="" />
                      </div>
                      <input className="inputData input-addon__input" type="text" value={emailUser} onChange={(evet)=>setEmailUser(evet.target.value)} />
                      <div className="input-addon__addon input-addon__addon--appended">
                        <button className="editData" type="button">EDIT</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
            <div className="containerMain">
              <div className="containerSecond">
                <div className="spanShareLink">
                  <span >Your Share Link:</span>
                </div>
                <div className="containerText">
                  <label className="labelShareLink" htmlFor="inputShare">plunderdesign.com/</label>
                  <input className="inputShareLink" type="text" value={userName} id="inputShare" onChange={(e)=>setUserName(e.target.value)} ></input>
                </div>
                <div className="containerButtonEdit" >
                  <button type="button" className="buttonEdit" onClick={handleClickOpenModalName} >EDIT</button>
                </div>
              </div>
              <div>
                <ul>
                  <li>• 4-30 characters long</li>
                  <li>• Only contain letters (aA-zZ) and numbers (0-9)</li>
                  <li>• No special characters like !@#$%</li>
                  <li>• Your username is also your replicated site address.</li>
                </ul>
              </div>

            </div>

            {/*actualizar nombre usuario*/}
            <Dialog
              open={openModalName}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseModalName}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>
                <div className="set-font-family-res">
                  <h2 style={{ fontSize: 20 }}>INFORMATION</h2>
                </div>
                </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {msj}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button 
                  variant="outlined"
                  onClick={handleCloseModalName} 
                  sx={{backgroundColor:"#000",color:"#fff",'&:hover':{backgroundColor:"#808080",color:"#fff",borderColor:"#808080"}}}
                >CANCEL</Button>
                <Button
                  variant="contained"
                  sx={{backgroundColor:"#f2dedb",color:"#000",'&:hover':{backgroundColor:"#f2dedb",color:"#000"}}}
                  onClick={btnUpdateName}
                >
                  ACCEPT
                </Button>
                
              </DialogActions>
            </Dialog>



            {/*modal mensaje de error con el nombre de usuario*/}  
            <div>
              <Modal
                open={stateModalError}
                onClose={hideModalError}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles}>
                <div className="containerCardError">
                  <div className="cabeceraWithButton">
                    <div className="titleCard">
                      <span className="textErrorTitleModal">Error - That name already in use!</span>
                    </div>
                    <div className="buttonCloseModal" onClick={hideModalError}>
                      <img src="https://cdn-icons-png.flaticon.com/512/1620/1620739.png" style={{width: "20px", height:" 20px"}} alt=""></img>
                    </div>
                  </div>
                  <div className="bodyCardModalError">
                    <span className="titleBodyErrorModal">PLEASE TRY CHOOSING ANOTHER NAME...</span>
                    <ul>
                      <li>• 4-30 characters long</li>
                      <li>• Only contain letters (aA-zZ) and numbers (0-9)</li>
                      <li>• No special characters like !@#$%</li>
                      <li>• Your username is also your replicated site address.</li>
                    </ul>
                    <div className="containerSecondModalError">
                      <div className="containerText">
                        <label className="labelShareLink" htmlFor="inputShare">plunderdesign.com/</label>
                        <input className="inputShareLink" type="text" id="inputShare" value={userName} onChange={(e)=>setUserName(e.target.value)}  />
                      </div>
                      <div className="containerButtonEdit" >
                        <button type="button" className="buttonEdit" onClick={btnUpdateName} >SAVE</button>
                      </div>
                    </div>
                    <div className="msjError">
                      <span className="supportModalError">Trouble creating your username/website? Contact support@plunderdesign.com</span>
                    </div>
                  </div>

                </div>
                </Box>
              </Modal>
            </div>
              
            <div>
              <Modal
                open={stateMsjCorrect}
                onClose={hidemsjCorrectUpName}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styles}>
                  <div className="containerCardError-v2">
                    <img src="./img/checked.png" alt="" style={{width:"150px", height:"150px"}} ></img>
                    <div style={{marginTop:10}}>
                      <span className="textErrorTitleModal">Your Username / Website has been saved!</span>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const availabilityData = {
  availability: "MY POSSE BOX SUBSCRIPTION",
  ic_Check:
    "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/ic-check--background-mask--1@1x.png",
  overlapGroup:
    "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/circle-10@1x.png",
  circle1:
    "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/circle-11@1x.png",
  online: "On",
  ic_Uncheck:
    "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/ic-check--background-mask--1@1x.png",
  circle2:
    "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/circle-13@1x.png",
  offline: "Cancel",
};

const actionData = {
  src: "https://anima-uploads.s3.amazonaws.com/projects/629b931af4af1b5122078435/releases/62c724a60e8c93c470159b17/img/background-2-1x-png@1x.png",
};

const group236Data = {
  availabilityProps: availabilityData,
  actionProps: actionData,
};
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default DesktopMyAccount;

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "25%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  borderRadius:'10px'
};
