import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RecentActivity from "../NewRecentActivity";
import Group73 from "../NewGroup73";
import PointsProgressBar from "../NewPointsProgressBar";
import ButtonWithdrawAllEarning from "../NewButtonWithdrawAllEarning";
import ButtonWithdrawCouponEarning from "../NewButtonWithdrawCouponEarning";
import ButtonWithdrawExclusiveEarning from "../NewButtonWithdrawExclusiveEarning";
import Dashboard1 from "../NewDashboard1";
import RewardsReports from "../NewRewardsReports";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import "./DesktopHomeStylistDashboard.css";

function DesktopHomeStylistDashboard(props) {
  Date.isLeapYear = function (year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  Date.getDaysInMonth = function (year, month) {
    return [
      31,
      Date.isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];
  };
  Date.prototype.isLeapYear = function () {
    var y = this.getFullYear();
    return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
  };
  Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
  };
  Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
  };
  const fechaActual = new Date();
  const actualDay = fechaActual.toDateString().split(" ")[2];
  const actualMonth = fechaActual.getMonth() + 1;
  const actualYear = fechaActual.getFullYear();

  const sixMonthsLater = new Date(
    `${actualMonth}/${actualDay}/${actualYear}`
  ).addMonths(6);

  const dateAff = JSON.stringify(sixMonthsLater).split("-");
  const monthAff = dateAff[1];
  const dayAff = dateAff[2].split("T")[0];
  const yearAff = dateAff[0].slice(1, 5);

  let email = params.email;
  const [isLoading, setLoading] = useState();
  const [firstName, setFirstName] = useState();
  const [customerType, setCustomerType] = useState();
  const [open, setOpen] = useState();
  const [idUser, setIdUser] = useState();
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonusPointsT, setBonusPointsT] = useState(0);
  const [points, setPoints] = useState(0);
  const [lastsix, setLastSix] = useState();
  const [paymentComplete, setPaymentComplete] = useState(0);
  const [datos, setDatos] = useState([]);
  const [userName, setUserName] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [checkCustomer, setCheckCustomer] = useState("");
  const [dataAffiliate, setDataAffiliate] = useState("");

  const {
    group252,
    activityLogs1,
    path641,
    iconStar,
    lifetimeMember,
    price,
    youAlwaysEarn10Rewards,
    group253,
    activityLogs2,
    group254,
    iconGift1,
    path642,
    iconGift2,
    x20Points,
    x30Points,
    x50Points,
    address1,
    address2,
    address3,
    address4,
    x75PointsGift,
    spanText1,
    spanText2,
    overlapGroup1,
    exclusiveItemAccess,
    number1,
    group249,
    overlapGroup2,
    x50OffCoupons,
    group248,
    number2,
    redeemInCart1,
    availablePoints,
    number3,
    group246,
    redeemInCart2,
    path51,
    path52,
    path53,
    path54,
    bonusRewards,
    forEvery200InPurchases,
    learnMore1,
    group239,
    percent,
    always10Per1Spent,
    onPersonalReferr,
    learnMore2,
    dashboard1,
    proposal,
    projects1,
    iconUser,
    projects2,
    group281,
    projects3,
    group292,
    projects4,
    group284,
    projects5,
    plunderBlackLogo250,
    title,
    welcome,
    activityLogs3,
    plunderdesignComXxxxxxx,
    dashboard2,
    recentActivityProps,
    pointsProgressBar1Props,
    buttonWithdrawAllEarningProps,
    pointsProgressBar2Props,
    dashboard1Props,
    group82Props,
  } = props;

  const emailLS = localStorage.getItem("correo_usuario");

  if (!email && emailLS) email = emailLS;

  const data = { email: email };
  const  [valCountry,setvalCountry]=useState(200);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch("https://deploywk.herokuapp.com/plunder-customer-dashboard/", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setFirstName(res[0]["firstName"]);
        setCustomerType(res[0]["customerType"]);
        if(res[0]["customData"]!==null){
          // console.log("no es nulo");
          if(res[0]["customData"]["country"]==="Canada"){
            setvalCountry(250);
          }
        }
        setIdUser(res[0]["id"]);
        // if (res[0]["customData"]) {
        //   setDataAffiliate(JSON.parse(res[0]["customData"]));
        // }
        localStorage.setItem("correo_usuario", email);
        localStorage.setItem("infoUsuario", JSON.stringify(res[0]));
        localStorage.setItem("customerType", res[0]["customerType"]);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(
      `https://deploywk.herokuapp.com/plunder-customer-data/plunder/${email}/`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLastSix(response["lastsix"]["value"]);
        setCheckCustomer(localStorage.getItem("customerType"));
        setDatos(response["lasttransactions"]);
        setUserName(response["username"]);
        if (response["subscriptions"]["nm_response"]) {
          setPaymentComplete(
            parseInt(
              response["subscriptions"]["nm_response"]["subscription"][
                "completed_payments"
              ]
            )
          );
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        // console.log(error);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"].length != 0) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "PlunderPoints") {
              setPoints(bonus["amount"] - bonus["released"]);
              setTotalPoints(bonus["amount"]);
            }
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"] - bonus["released"]);
              setBonusPointsT(bonus["amount"]);
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(Math.trunc(bonus["amount"]));
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setDiscountValue(Math.trunc(bonus["amount"]));
              setReleasedVaule(Math.trunc(bonus["released"]));
            }
          });
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((res) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT 2.0");
        setLoading(false);
        setOpen(false);
      });
  }, [idUser]);

  // Setting differents classes depending of the numbers
  const finalPoints =
    points + bonusPoints - Math.trunc(points + bonusPoints)
      ? Math.trunc(points + bonusPoints) + 1
      : Math.trunc(points + bonusPoints);

  const newPoints = Math.trunc(points + bonusPoints);
  const classRewards = `new-number-2-${
    finalPoints.toString().length
  }-numbers new-avenir-medium-black-50px`;

  const offCouponds = discount - released;
  const classCouponds = `new-number-1-${
    offCouponds.toString().length
  }-numbers new-avenir-medium-black-50px`;

  const classItemValue = `new-number-${
    itemValue.toString().length
  }-numbers new-avenir-medium-black-50px`;

  return (
    <div className="new-container-center-horizontal ">
      <div className="new-desktop-home-stylist-dashboard new-screen">
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
        {!isLoading && (
          <div className="new-overlap-group9">
            <div className="new-background-container">
              <div className="new-background"></div>
              <div className="new-background-1"></div>
            </div>
            {idUser && (
              <RecentActivity
                group1031Props={recentActivityProps.group1031Props}
                group1032Props={recentActivityProps.group1032Props}
                group98Props={recentActivityProps.group98Props}
                dateProps={recentActivityProps.dateProps}
                email={email}
                idUser={idUser}
                datos={datos}
                lastsix={lastsix}
                customerType={customerType}
                dataAffiliate={dataAffiliate}
              />
            )}

            <div className="new-rewards-chart">
              <div className="new-group-78">
                <div className="new-group-container">
                  <Group73
                    totalPoints={totalPoints}
                    bonusPoints={bonusPointsT}
                    lastsix={lastsix}
                  />
                  <a href="javascript:ShowOverlay('stylist-pop-up-2', 'new-animate-appear');">
                    <div
                      className="new-group-252"
                      style={{ backgroundImage: `url(${group252})` }}
                    ></div>
                  </a>
                </div>
                {customerType !== "customer" && customerType !== "affiliate" && (
                  <div className="new-overlap-group3">
                    <div className="new-activity-logs new-avenir-heavy-normal-eerie-black-20px">
                      {customerType !== "customer" &&
                      customerType !== "affiliate"
                        ? "stylist rewards"
                        : "track to affiliate"}
                    </div>
                    <div className="new-overlap-group1">
                      <div className="new-overlap-group">
                        <div className="new-rectangle-31 new-border-2px-dove-gray"></div>
                        <progress
                          className="new-rectangle-32 new-border-2px-dove-gray new-barra-2"
                          max={valCountry}
                          value={valCountry}
                        >${Math.round(lastsix)}</progress>
                        <img className="new-path-64" src={path641} />
                        <img className="new-icon-star" src={iconStar} />
                      </div>
                      {checkCustomer === "stylist" && (
                        <div className="new-lifetime-member new-avenir-heavy-normal-white-24px">
                          {/*lifetimeMember*/}
                        </div>
                      )}
                      {lastsix <= valCountry && checkCustomer !== "stylist" && (
                        <div className="new-lifetime-member new-avenir-heavy-normal-white-24px new-change-color">
                          ${Math.round(lastsix)}
                        </div>
                      )}
                      <div className="new-price new-avenir-heavy-normal-sonic-silver-16px">
                        {price}
                      </div>
                      {checkCustomer === "stylist" ? (
                        <p className="new-you-always-earn-10-rewards new-avenir-heavy-normal-sonic-silver-14px">
                          {youAlwaysEarn10Rewards}
                        </p>
                      ) : (
                        <p className="new-you-always-earn-10-rewards new-avenir-heavy-normal-sonic-silver-14px new-change-position">
                          ${Math.round(valCountry - lastsix)} more to always earn 10%
                          rewards
                        </p>
                      )}
                    </div>
                    <a href="javascript:ShowOverlay('stylist-pop-up-1', 'new-animate-appear');">
                      <div
                        className="new-group-253"
                        style={{ backgroundImage: `url(${group253})` }}
                      ></div>
                    </a>
                  </div>
                )}
                {(customerType === "customer" ||
                  customerType === "affiliate") && (
                  <div className="new-overlap-group3">
                    <div className="new-activity-logs new-avenir-heavy-normal-eerie-black-20px">
                      {customerType !== "customer" &&
                      customerType !== "affiliate"
                        ? "stylist rewards"
                        : "track to affiliate"}
                    </div>
                    <div className="new-overlap-group1">
                      <div className="new-overlap-group">
                        <div className="new-rectangle-31 new-border-2px-dove-gray"></div>
                        <progress
                          className="new-rectangle-32 new-border-2px-dove-gray new-barra-2"
                          max={valCountry}
                          value={lastsix}
                        ></progress>
                        <img className="new-path-64" src={path641} />
                        <img className="new-icon-star" src={iconStar} />
                      </div>
                      {checkCustomer === "affiliate" && (
                        <div className="new-lifetime-member new-avenir-heavy-normal-white-24px">
                          Congrats, you did it!
                        </div>
                      )}
                      {checkCustomer === "customer" && (
                        <div className="new-lifetime-member new-avenir-heavy-normal-white-24px new-change-color">
                          ${Math.round(lastsix)}
                        </div>
                      )}
                      <div className="new-price new-avenir-heavy-normal-sonic-silver-16px">
                        {price}
                      </div>
                      {customerType === "affiliate" && (
                        <p className="new-you-always-earn-10-rewards new-avenir-heavy-normal-sonic-silver-14px new-plus-position">
                          you're an affiliate until{" "}
                          {`${monthAff}/${dayAff}/${yearAff}`}
                        </p>
                      )}

                      {checkCustomer === "customer" && (
                        <p className="new-you-always-earn-10-rewards new-avenir-heavy-normal-sonic-silver-14px new-change-position new-more-position">
                          ${Math.round(valCountry - lastsix)} more by{" "}
                          {`${monthAff}/${dayAff}/${yearAff}`} to level up to
                          10% rewards
                        </p>
                      )}
                    </div>
                    <a href="javascript:ShowOverlay('stylist-pop-up-1', 'new-animate-appear');">
                      <div
                        className="new-group-253"
                        style={{ backgroundImage: `url(${group253})` }}
                      ></div>
                    </a>
                  </div>
                )}

                <div className="new-group-76">
                  <div className="new-overlap-group4">
                    <div className="new-activity-logs new-avenir-heavy-normal-eerie-black-20px">
                      {activityLogs2}
                    </div>
                    <a href="javascript:ShowOverlay('stylist-pop-up-4', 'new-animate-appear');">
                      <div
                        className="new-group-254"
                        style={{ backgroundImage: `url(${group254})` }}
                      ></div>
                    </a>
                  </div>
                  <div className="new-group-74">
                    <div className="new-group-container-1">
                      <div className="new-overlap-group-1">
                        <div className="new-rectangle-31-1 new-border-2px-dove-gray"></div>
                        <progress
                          className="new-rectangle-32 new-border-2px-dove-gray new-barra-2 new-barra-progress-2"
                          max={valCountry}
                          value={paymentComplete}
                        ></progress>
                        <img className="new-icon-gift" src={iconGift1} />
                      </div>
                      <div className="new-group-77">
                        <div className="new-overlap-group1-1">
                          <div className="new-rectangle-31-2 new-border-2px-dove-gray"></div>
                          <div className="new-rectangle-33-1 new-border-2px-dove-gray"></div>
                          <img className="new-path-64-1" src={path642} />
                          <img className="new-icon-gift-1" src={iconGift2} />
                          <div className="new-x20points new-avenir-heavy-normal-white-20px">
                            {x20Points}
                          </div>
                          <div className="new-x30points new-avenir-heavy-normal-white-20px">
                            {x30Points}
                          </div>
                          <div className="new-x50points new-avenir-heavy-normal-pale-sky-20px">
                            {x50Points}
                          </div>
                          <div className="new-address new-avenir-heavy-normal-sonic-silver-14px">
                            {address1}
                          </div>
                          <div className="new-address-1 new-avenir-heavy-normal-sonic-silver-14px">
                            {address2}
                          </div>
                          <div className="new-address-2 new-avenir-heavy-normal-sonic-silver-14px">
                            {address3}
                          </div>
                        </div>
                        <div className="new-flex-col">
                          <div className="new-address-3 new-avenir-heavy-normal-sonic-silver-14px">
                            {address4}
                          </div>
                          <div className="new-x75points-gift new-avenir-heavy-normal-pale-sky-20px">
                            {x75PointsGift}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="new-address-4 new-avenir-medium-sonic-silver-14px">
                      <span className="new-avenir-heavy-normal-sonic-silver-14px">
                        {paymentComplete}
                      </span>
                      <span className="new-avenir-medium-sonic-silver-14px-2">
                        {spanText2}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <PointsProgressBar>
              {pointsProgressBar1Props.children}
            </PointsProgressBar>
            <div>
              <div className="new-overlap-group6">
                <div
                  className="new-overlap-group-2"
                  style={{ backgroundImage: `url(${overlapGroup1})` }}
                >
                  <div className="new-share-link">
                    <div className="new-text-earning-this-month">
                      <div className="new-group-1">
                        <div className="new-exclusive-item-access new-avenir-medium-log-cabin-20px">
                          {exclusiveItemAccess}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ButtonWithdrawExclusiveEarning />
                <div className={classItemValue}>{itemValue}</div>
                <div
                  className="new-group-249"
                  style={{ backgroundImage: `url(${group249})` }}
                ></div>
              </div>
              <div className="new-overlap-group7">
                <div
                  className="new-overlap-group-3"
                  style={{ backgroundImage: `url(${overlapGroup2})` }}
                >
                  <div className="new-share-link-1">
                    <div className="new-text-earning-this-month-1">
                      <div className="new-group-1-1">
                        <div className="new-x50-off-coupons new-avenir-medium-log-cabin-20px">
                          {x50OffCoupons}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="new-group-248"
                  style={{ backgroundImage: `url(${group248})` }}
                >
                  <div className={classCouponds}>{offCouponds}</div>
                </div>
                <ButtonWithdrawCouponEarning />
                <div className="new-redeem-in-cart new-avenir-heavyoblique-normal-eerie-black-14px">
                  {redeemInCart1}
                </div>
              </div>
              <div className="new-overlap-group8">
                <div className="new-card-rewards">
                  <div className="new-earning-report">
                    <div className="new-text-earning-this-month-2">
                      <div className="new-available-points new-avenir-medium-log-cabin-20px">
                        {availablePoints}
                      </div>
                      <div className="new-group-247">
                        <div className="new-overlap-group-4">
                          <div className={classRewards}>{newPoints}</div>
                          <div
                            className="new-group-246"
                            style={{ backgroundImage: `url(${group246})` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ButtonWithdrawAllEarning />
                <div className="new-redeem-in-cart-1 new-avenir-heavyoblique-normal-eerie-black-14px">
                  {redeemInCart2}
                </div>
              </div>
            </div>

            <PointsProgressBar className={pointsProgressBar2Props.className}>
              {pointsProgressBar2Props.children}
            </PointsProgressBar>

            <div
              className={`new-card-explainer-2 ${
                checkCustomer !== "stylist" && "change-rewards"
              }`}
            >
              <div className="new-group-64">
                <div className="new-path-container">
                  <img className="new-path-51" src={path51} />
                  <img className="new-path-52" src={path52} />
                  <img className="new-path-53" src={path53} />
                  <img className="new-path-54" src={path54} />
                </div>
              </div>
              <div className="new-bonus-rewards new-avenir-heavy-normal-eerie-black-18px">
                {bonusRewards}
              </div>

              <p className="new-for-every-200-in-purchases new-avenir-book-normal-eerie-black-12px">
                {forEvery200InPurchases}
              </p>
              <a href="javascript:ShowOverlay('stylist-pop-up-2', 'new-animate-appear');">
                <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                  {learnMore1}
                </div>
              </a>
            </div>

            {checkCustomer !== "stylist" && (
              <div className="new-card-explainer-2 card-level-up change-level">
                <div className="new-group-64">
                  <div className="new-path-container container-card-level">
                    <img
                      className="new-path-51 card-level-up-img"
                      src="https://i.imgur.com/m86sViN.png"
                    />
                  </div>
                </div>
                {checkCustomer !== "stylist" && (
                  <div className="new-bonus-rewards new-avenir-heavy-normal-eerie-black-18px">
                    LEVEL UP
                  </div>
                )}
                <p className="new-for-every-200-in-purchases new-avenir-book-normal-eerie-black-12px">
                  {checkCustomer === "customer"
                    ? "ACHIEVE AFFILIATE & EARN 10% PER $1"
                    : "BECOME A STYLIST & EARN CASH DOLLARS"}
                </p>
                <a href="javascript:ShowOverlay('stylist-pop-up-2', 'new-animate-appear');">
                  <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                    {learnMore1}
                  </div>
                </a>
              </div>
            )}

            <div
              className={`new-card-expainer-1 ${
                checkCustomer !== "stylist" && "change-percent"
              }`}
            >
              <div
                className="new-group-239"
                style={{ backgroundImage: `url(${group239})` }}
              >
                <div className="new-percent">
                  {customerType !== "customer" || lastsix >= valCountry ? 10 : 5}%
                </div>
              </div>
              <div className="new-always-10-per-1-spent new-avenir-heavy-normal-eerie-black-18px">
                {checkCustomer !== "stylist" ? "" : "ALWAYS"}{" "}
                {customerType !== "customer" || lastsix >= valCountry ? 10 : 5}% PER $1
                SPENT
              </div>
              <p className="new-on-personal-referr new-avenir-book-normal-eerie-black-12px">
                ON PERSONAL & REFERRED FRIEND'S ORDERS
              </p>
              <a href="javascript:ShowOverlay('stylist-pop-up-1', 'new-animate-appear');">
                <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                  {learnMore2}
                </div>
              </a>
            </div>

            <div className="new-sidebar">
              <div className="new-menus new-avenir-medium-black-18px">
                <div className="new-x01">
                  <div className="new-group-300">
                    <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                    <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                      {dashboard1}
                    </div>
                  </div>
                </div>
                {/* <Link
                  to="/desktop-my-connections-ecwid-social-connect-dashboard"
                  className="none-decoration"
                >
                  <div className="new-x02">
                    <div className="new-group-301">
                      <div
                        className="new-proposal"
                        style={{ backgroundImage: `url(${proposal})` }}
                      ></div>
                      <div className="new-projects">{projects1}</div>
                    </div>
                  </div>
                </Link> */}

                <Link to="/desktop-my-account" className="none-decoration">
                  <div className="new-group-83">
                    <div className="new-group-82">
                      <img className="new-icon-user" src={iconUser} />
                      <div className="new-projects-1 new-avenir-medium-black-18px">
                        {projects2}
                      </div>
                    </div>
                  </div>
                </Link>
                {customerType !== "customer" && (
                  <Link
                    to="/desktop-my-stylist-rewards-reports"
                    className="none-decoration"
                  >
                    <div className="new-group-84">
                      <RewardsReports
                        group85Props={group82Props.group85Props}
                      />
                    </div>
                  </Link>
                )}

                {customerType !== "customer" && (
                  <Link
                    to="/desktop-create-a-cart-stylist-only"
                    className="none-decoration"
                  >
                    <div className="new-group-84-1">
                      <div className="new-group-82-1">
                        <div className="new-group-302">
                          <div
                            className="new-group-281"
                            style={{ backgroundImage: `url(${group281})` }}
                          ></div>
                          <div className="new-projects-2">{projects3}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
                {customerType == "stylist" && (
                  estadoBackOffice &&(
                    <a className="none-decoration">
                      <div className="new-group-2" onClick={()=>linkto()} >
                        <div className="new-group-82-2">
                          <div className="new-group-303">
                            <div
                              className="new-group-292"
                              style={{ backgroundImage: `url(${group292})` }}
                            ></div>
                            <div className="new-projects-3">{projects4}</div>
                          </div>
                        </div>
                      </div>
                    </a>
                  )
                )}
                {/* {customerType !== "customer" && (
                  <a
                    href={`https://test-plunderdesign.myvoffice.com/callback/index.cfm?mid=${idUser}`}
                    target="_blank"
                    className="none-decoration"
                  >
                    <div className="new-group-2">
                      <div className="new-group-82-2">
                        <div className="new-group-303">
                          <div
                            className="new-group-292"
                            style={{ backgroundImage: `url(${group292})` }}
                          ></div>
                          <div className="new-projects-3">{projects4}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                )} */}
                <div className="new-group-2">
                  <div className="new-group-304">
                    <div
                      className="new-group-284"
                      style={{ backgroundImage: `url(${group284})` }}
                    ></div>
                    <div className="new-group-82-3">
                      <div className="new-projects-4">{projects5}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-header">
              <div className="new-group-112">
                <div className="new-header-1">
                  <div className="new-group-268">
                    <div className="new-overlap-group-5">
                      <img
                        className="new-plunder-black-logo-250"
                        src={plunderBlackLogo250}
                      />
                      <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                        {title}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-welcome">
                <div className="new-overlap-group1-2">
                  <div className="new-welcome-1 new-avenir-book-normal-black-18px">
                    Hello {firstName}, welcome back!
                  </div>
                  <div className="new-activity-logs-1">
                    {customerType !== "customer" && customerType !== "affiliate"
                      ? "stylist"
                      : lastsix >= valCountry
                      ? "affiliate"
                      : "customer"}{" "}
                    rewards member
                  </div>
                </div>
              </div>
              <div className="new-copy-link">
                <div className="new-group-89 new-border-0-5px-dove-gray">
                  <div className="new-plunderdesigncomxxxxxxx">
                  plunderdesign.com/?sponsor={userName}
                  </div>
                </div>
                <div
                  className="new-x01-1"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(`plunderdesign.com/?sponsor=${userName}`)
                      .then(() => {
                        setCopiado(true);
                        console.log("Text copied to clipboard...");
                      })
                      .catch((err) => {
                        setCopiado(false);
                        console.log("Something went wrong", err);
                      });
                  }}
                >
                  <div className="new-dashboard-1 new-avenir-medium-cod-gray-18px">
                    {copiado ? "COPIED" : "COPY LINK"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default DesktopHomeStylistDashboard;
