import React from "react";
import Group40 from "../Group40";
import "./ModalBloqueo.css";
import Lose from "../../static/img/lose.png";

function ModalBloqueo(props) {
  const {
    desktopTopRightMenu,
    background,
    addFriend2,
    loremIpsum,
    path43,
    dolorSitAmet,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div
        className="desktop-top-right-modal screen"
        style={{ backgroundImage: `url(${desktopTopRightMenu})` }}
      >
        <div className="overlap-group-4">
          <div className="background-modal alert-lose">
            <h1>
              <b className="message-error">Sorry!</b>
            </h1>
            <br />
            <h2>
              <b className="message-error">The user has no information</b>
            </h2>
            <div>
              <img src={Lose} className="imagen-lose"/>
            </div>
          </div>
        </div>
      </div>``
    </div>
  );
}

export default ModalBloqueo;
