import React from "react";
import "./Date.css";

function DateActual(props) {
  const { className, date } = props;
  const currentDate = new Date().toString();
  return (
    <div className={`date-6-1 ${className || ""}`}>
      <div className="calendar-1"></div>
      <div className="date-7 new-manrope-normal-quick-silver-10px">{date}</div>
    </div>
  );
}

export default DateActual;
