import React from "react";
import RewardsIcon from "../NewRewardsIcon";
import "./Group82.css";

function RewardsReports(props) {
  const { className, group85Props } = props;

  return (
    <div className={`new-group-82-4 ${className || ""}`}>
      <RewardsIcon className="group-85 " />
      <div className="new-projects-5 new-avenir-medium-black-18px">My Rewards Reports</div>
    </div>
  );
}

export default RewardsReports;
