import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RecentActivity from "../NewRecentActivity Res";
import Group73 from "../NewGroup73";
import PointsProgressBar from "../NewPointsProgressBar";
// import ButtonWithdrawAllEarning from "../NewButtonWithdrawAllEarning";
// import ButtonWithdrawCouponEarning from "../NewButtonWithdrawCouponEarning";
import ButtonWithdrawExclusiveEarning from "../NewButtonWithdrawExclusiveEarning";
import Dashboard1 from "../NewDashboard1";
import RewardsReports from "../NewRewardsReports";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "react-modal";


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import SmsIcon from '@mui/icons-material/Sms';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import PinterestIcon from '@mui/icons-material/Pinterest';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';


import "./DesktopHomeStylistDashboard.css";
import { width } from "@mui/system";
import { Psychology } from "@mui/icons-material";

function DesktopHomeStylistDashboard(props) {
  Date.isLeapYear = function (year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  Date.getDaysInMonth = function (year, month) {
    return [
      31,
      Date.isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];
  };
  Date.prototype.isLeapYear = function () {
    var y = this.getFullYear();
    return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
  };
  Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
  };
  Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
  };

  let email = params.email;
  const [isLoading, setLoading] = useState();
  const [firstName, setFirstName] = useState();
  const [customerType, setCustomerType] = useState();
  const [open, setOpen] = useState();
  const [idUser, setIdUser] = useState();
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonusPointsT, setBonusPointsT] = useState(0);
  const [points, setPoints] = useState(0);
  const [lastsix, setLastSix] = useState();
  const [paymentComplete, setPaymentComplete] = useState(0);
  const [datos, setDatos] = useState([]);
  const [userName, setUserName] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [checkCustomer, setCheckCustomer] = useState("");
  const [dataAffiliate, setDataAffiliate] = useState("");

  const [showHamburger, setShowHamburger] = useState(true);

  const {
    group252,
    activityLogs1,
    path641,
    iconStar,
    lifetimeMember,
    price,
    youAlwaysEarn10Rewards,
    group253,
    activityLogs2,
    group254,
    iconGift1,
    path642,
    iconGift2,
    x20Points,
    x30Points,
    x50Points,
    address1,
    address2,
    address3,
    address4,
    x75PointsGift,
    spanText1,
    spanText2,
    overlapGroup1,
    exclusiveItemAccess,
    number1,
    group249,
    overlapGroup2,
    x50OffCoupons,
    group248,
    number2,
    redeemInCart1,
    availablePoints,
    number3,
    group246,
    redeemInCart2,
    path51,
    path52,
    path53,
    path54,
    bonusRewards,
    forEvery200InPurchases,
    learnMore1,
    group239,
    percent,
    always10Per1Spent,
    onPersonalReferr,
    learnMore2,
    dashboard1,
    proposal,
    projects1,
    iconUser,
    projects2,
    group281,
    projects3,
    group292,
    projects4,
    group284,
    projects5,
    plunderBlackLogo250,
    title,
    welcome,
    activityLogs3,
    plunderdesignComXxxxxxx,
    dashboard2,
    recentActivityProps,
    pointsProgressBar1Props,
    buttonWithdrawAllEarningProps,
    pointsProgressBar2Props,
    dashboard1Props,
    group82Props,
  } = props;

  const emailLS = localStorage.getItem("correo_usuario");

  const [country,setcountry]=useState("");
  const [valCountry,setvalCountry]=useState(200);
  const [estadoBackOffice,setestadoBackOffice]=useState(true);
  const [estadoPersonalizeOrder,setestadoPersonalizeOrder]=useState(true);
  const [susbs,setsusbs]=useState(0);


  if (!email && emailLS) email = emailLS;

  const data = { email: email };

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    console.log("email que se envia en el endpoint");
    console.table(data.email);
    fetch("https://deploywk.herokuapp.com/plunder-customer-dashboard/", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setFirstName(res[0]["firstName"]);
        setCustomerType(res[0]["customerType"]);
        if(res[0]["customData"]!==null){
          if(res[0]["customData"]["country"]==="Canada"){
            setvalCountry(250);
          }
        }

        setIdUser(res[0]["id"]);
        localStorage.setItem("correo_usuario", email);
        localStorage.setItem("infoUsuario", JSON.stringify(res[0]));
        localStorage.setItem("customerType", res[0]["customerType"]);
        // setLoading(false);
        // setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
        // setOpen(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(
      `https://deploywk.herokuapp.com/plunder-customer-data/plunder/${email}/`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((response) => { 
        if (JSON.stringify(response["subscriptions"])!='{}'){
          setsusbs(parseInt(response["subscriptions"]["months"]));
        }
        setLastSix(response["lastsix"]["value"]);
        setCheckCustomer(localStorage.getItem("customerType"));
        setDatos(response["lasttransactions"]);
        setUserName(response["username"]);
        setLoading(false);
        setOpen(false);
        
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        // console.log(error);
        setLoading(false);
        setOpen(false);
      });
  }, []);


  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"] !== []) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "PlunderPoints") {
              setPoints(bonus["amount"] - bonus["released"]);
              setTotalPoints(bonus["amount"]);
            }
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"] - bonus["released"]);
              setBonusPointsT(bonus["amount"]);
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(Math.trunc(bonus["amount"]-bonus["released"]));
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setDiscountValue(Math.trunc(bonus["amount"]));
              setReleasedVaule(Math.trunc(bonus["released"]));
            }
          });
        }
        // setLoading(false);
        // setOpen(false);
      })
      .catch((res) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT 2.0");
        setLoading(false);
        setOpen(false);
      });
  }, [idUser]);

  // Setting differents classes depending of the numbers
  const finalPoints =
    points + bonusPoints - Math.trunc(points + bonusPoints)
      ? Math.trunc(points + bonusPoints) + 1
      : Math.trunc(points + bonusPoints);

  const newPoints = Math.trunc(points + bonusPoints);
  const classRewards = `new-number-2-${
    finalPoints.toString().length
  }-numbers new-avenir-medium-black-50px`;


  const offCouponds = discount - released;
  const classCouponds = `new-number-1-${
    offCouponds.toString().length
  }-numbers new-avenir-medium-black-50px`;

  const classItemValue = `new-number-${
    itemValue.toString().length
  }-numbers new-avenir-medium-black-50px`;
  let dateAff;
  let monthAff;
  let dayAff;
  let yearAff;
  if (datos.length!=0) {
    const fechaActual = new Date(datos.reverse()[0]["date"]);
    const actualDay = fechaActual.toDateString().split(" ")[2];
    const actualMonth = fechaActual.getMonth() + 1;
    const actualYear = fechaActual.getFullYear();

    const sixMonthsLater = new Date(
      `${actualMonth}/${actualDay}/${actualYear}`
    ).addMonths(6);

    dateAff = JSON.stringify(sixMonthsLater).split("-");
    monthAff = dateAff[1];
    dayAff = dateAff[2].split("T")[0];
    yearAff = dateAff[0].slice(1, 5);
  }
  // =====================================  
  const linkto = ()=>{
    window.open(`https://authworkinglive.com/auth/loginv3?email=${email.toLowerCase()}`,'_blank');
  }

  
  window.addEventListener('message', function (event) {
    if (event.data == "LogoutDashboard") {   
      console.log(event);
    }
  });

  const [modalIsOpen, setIsOpen] = useState(false);

  
  

  return (
    <>
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </div>

      {!isLoading && (
        <div className="new-overlap-group9-res">
          <div
            className="show-hamburger-menu"
            onClick={() => {
              setShowHamburger(!showHamburger);
            }}
          >
            <a>
              <img
                width={25}
                height={25}
                src="https://i.imgur.com/I8jdeMN.png"
              ></img>
            </a>
          </div>
          <div hidden={showHamburger} className="new-sidebar1-res">
            <div className="new-group-112-res">
              <div className="">
                <div className="new-group-268">
                  <div className="new-overlap-group-5">
                    <img
                      className="new-plunder-black-logo-250"
                      src={plunderBlackLogo250}
                    />
                    <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                      {title}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-menus-res new-avenir-medium-black-18px">
              <div className="new-x01">
                <div className="new-group-300">
                  <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                  <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                    {dashboard1}
                  </div>
                </div>
              </div>

              <Link to="/desktop-my-account" className="none-decoration">
                <div className="new-group-83">
                  <div className="new-group-82">
                    <img className="new-icon-user" src={iconUser} />
                    <div className="new-projects-1 new-avenir-medium-black-18px">
                      {projects2}
                    </div>
                  </div>
                </div>
              </Link>
              {
                estadoPersonalizeOrder&&(
                  <a className="none-decoration">
                    <div className="new-x02" onClick={()=>linkto()}>
                      <div className="new-group-301">
                        <div
                          className="new-proposal"
                          style={{ backgroundImage: `url(${proposal})` }}
                        ></div>
                        <div className="new-projects">Personalize Order</div>
                      </div>
                    </div>
                  </a>
                )
              }
              {
                email == "jamie@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }
              {
                email == "tylortest10@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <Link
                      to="/desktop-my-stylist-rewards-reports"
                      className="none-decoration"
                    >
                      <div className="new-group-84">
                        <RewardsReports group85Props={group82Props.group85Props} />
                      </div>
                    </Link>
                  )
                )
              }
              
              {checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                <Link
                  to="/desktop-create-a-cart-stylist-only"
                  className="none-decoration"
                >
                  <div className="new-group-84-1">
                    <div className="new-group-82-1">
                      <div className="new-group-302">
                        <div
                          className="new-group-281"
                          style={{ backgroundImage: `url(${group281})` }}
                        ></div>
                        <div className="new-projects-2">{projects3}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
              {/* =================== My back office================= */}
              {checkCustomer == "stylist" && (
                estadoBackOffice &&(
                  <a className="none-decoration">
                    <div className="new-group-2" onClick={()=>linkto()} >
                      <div className="new-group-82-2">
                        <div className="new-group-303">
                          <div
                            className="new-group-292"
                            style={{ backgroundImage: `url(${group292})` }}
                          ></div>
                          <div className="new-projects-3">{projects4}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              )}
              {/* {checkCustomer === "customer" || checkCustomer === "affiliate" || checkCustomer ==="stylist" && (
                
              )} */}
              
              <div className="new-group-2" onClick={()=>{top.postMessage("LogoutDashboard", '*');}}>
                <div className="new-group-304">
                  <div
                    className="new-group-284"
                    style={{ backgroundImage: `url(${group284})` }}
                    // este es el logout
                  ></div>
                  <div className="new-group-82-3">
                    <div className="new-projects-4">{projects5}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-main-home"
            onClick={() => {
              setShowHamburger(true);
            }}
          > 
            <div className="container-header-text-button">
              <div className="text-header-res">
                <div className="text-header-hello">
                  Hello {firstName}, welcome back!{" "}
                </div>
                <div className="text-header-bold">
                  {checkCustomer} rewards member
                </div>
              </div>
              <div className="container-copy-text">
                <div className="text-refer">
                  <div className="more-width-text" style={{textAlign:"left"}} >
                  plunderdesign.com/{userName}
                  </div>
                </div>
                <button
                  className="copy-text-refer"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(`plunderdesign.com/${userName}`)
                      .then(() => {
                        setCopiado(true);
                        // console.log("Text copied to clipboard...");
                      })
                      .catch((err) => {
                        setCopiado(false);
                        console.log("Something went wrong", err);
                      });
                  }}
                >
                  {copiado ? "COPIED" : "COPY"}
                </button>
                <button
                  className="copy-text-refer"
                  onClick={()=>{setIsOpen(true)}}
                >
                  SHARE
                </button>
              </div>
            </div>
            <div className="cards-container-rewards">
              <div
                className={`new-card-explainer-2-res ${
                  checkCustomer !== "stylist" && "change-rewards"
                }`}
              >
                <div className="new-group-64">
                  <div className="new-path-container">
                    <img className="new-path-51" src={path51} />
                    <img className="new-path-52" src={path52} />
                    <img className="new-path-53" src={path53} />
                    <img className="new-path-54" src={path54} />
                  </div>
                </div>
                <div className="new-bonus-rewards new-avenir-heavy-normal-eerie-black-18px">
                  {bonusRewards}
                </div>

                <p className="new-for-every-200-in-purchases new-avenir-book-normal-eerie-black-12px">
                  {forEvery200InPurchases}
                </p>
                <a href="javascript:ShowOverlay('stylist-pop-up-2', 'new-animate-appear');">
                  <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                    {learnMore1}
                  </div>
                </a>
              </div>

              <div
                className={`new-card-expainer-1-res ${
                  checkCustomer !== "stylist" && "change-percent"
                }`}
              >
                <div
                  className="new-group-239"
                  style={{ backgroundImage: `url(${group239})` }}
                >
                  <div className="new-percent">
                    {customerType !== "customer" || lastsix >= valCountry ? 10 : 5}%
                  </div>
                </div>
                <div className="new-always-10-per-1-spent new-avenir-heavy-normal-eerie-black-18px">
                  {checkCustomer !== "stylist" ? "" : "ALWAYS"}{" "}
                  {customerType !== "customer" || lastsix >= valCountry ? 10 : 5}% PER
                  $1 SPENT
                </div>
                <p className="new-on-personal-referr new-avenir-book-normal-eerie-black-12px">
                  ON PERSONAL & REFERRED FRIEND'S ORDERS
                </p>
                <a href="javascript:ShowOverlay('stylist-pop-up-1', 'new-animate-appear');">
                  <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                    {learnMore2}
                  </div>
                </a>
              </div>
              {checkCustomer !== "stylist" && (
                <div className="new-card-explainer-2-res card-level-up change-level">
                  <div className="new-group-64">
                    <div className="new-path-container container-card-level">
                      <img
                        className="new-path-51 card-level-up-img"
                        src="https://i.imgur.com/m86sViN.png"
                      />
                    </div>
                  </div>
                  {checkCustomer !== "stylist" && (
                    <div className="new-bonus-rewards new-avenir-heavy-normal-eerie-black-18px">
                      LEVEL UP
                    </div>
                  )}
                  <p className="new-for-every-200-in-purchases new-avenir-book-normal-eerie-black-12px">
                    {checkCustomer === "customer"
                      ? "ACHIEVE AFFILIATE & EARN 10% PER $1"
                      : "BECOME A STYLIST & EARN CASH DOLLARS"}
                  </p>
                  <a href="javascript:ShowOverlay('stylist-pop-up-2', 'new-animate-appear');">
                    <div className="new-learn-more new-avenir-medium-eerie-black-12px">
                      {learnMore1}
                    </div>
                  </a>
                </div>
              )}
            </div>
            <div className="points-progress-bar-res">
              {pointsProgressBar2Props.children}
            </div>
            <div className="container-rewards">
              
              <div className="new-overlap-group7-res">
                <div
                  className="new-overlap-group-3-res"
                  style={{ backgroundImage: `url(${overlapGroup2})` }}
                >
                  <div className="new-share-link-1">
                    <div className="new-text-earning-this-month-1">
                      <div className="new-group-1-1">
                        <div className="new-x50-off-coupons new-avenir-medium-log-cabin-20px"style={{display: "flex", justifyContent: "center"}}>
                          {availablePoints}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="new-group-248-res" style={{ backgroundImage: `url(${group246})`, zIndex:1 , backgroundRepeat:"no-repeat",backgroundSize: "contain" }}>
                      <div className="new-number-2-2-numbers new-avenir-medium-black-50px" style={{zIndex:2}}>{newPoints}</div>
                  </div>
                  <a
                    href="https://plunderdesign.com/shoppingcart/store/"
                    target="_blank"
                    className="button-rewards-fixed"
                    style={{paddingTop:10}}
                  >
                    <div className="button-withdraw-all-earning-2-res">
                      <div className="shop-now new-avenir-heavy-normal-black-16px">
                        SHOP NOW
                      </div>
                    </div>
                  </a>
                  <div className="new-redeem-in-cart-res new-avenir-heavyoblique-normal-eerie-black-14px">
                    {redeemInCart1}
                  </div>
                </div>
              </div>

              <div className="new-overlap-group7-res">
                <div
                  className="new-overlap-group-3-res"
                  style={{ backgroundImage: `url(${overlapGroup2})` }}
                >
                  <div className="new-share-link-1">
                    <div className="new-text-earning-this-month-1">
                      <div className="new-group-1-1">
                        <div className="new-x50-off-coupons new-avenir-medium-log-cabin-20px" style={{display: "flex", justifyContent: "center"}}> 
                          {x50OffCoupons}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="new-group-248-res"
                    style={{ backgroundImage: `url(${group248})` }}
                  >
                    <div className={classCouponds}>{offCouponds}</div>
                  </div>
                  <a
                    href="https://plunderdesign.com/shoppingcart/store/"
                    target="_blank"
                    className="button-rewards-fixed"
                    style={{paddingTop:10}}
                  >
                    <div className="button-withdraw-all-earning-2-res">
                      <div className="sho p-now new-avenir-heavy-normal-black-16px">
                        SHOP NOW
                      </div>
                    </div>
                  </a>
                  <div className="new-redeem-in-cart-res new-avenir-heavyoblique-normal-eerie-black-14px">
                    {redeemInCart1}
                  </div>
                </div>
              </div>
    
              <div className="new-overlap-group7-res">
                <div
                  className="new-overlap-group-3-res"
                  style={{ backgroundImage: `url(${overlapGroup1})` }}
                >
                  <div className="new-share-link">
                    <div className="new-text-earning-this-month">
                      <div className="new-group-1">
                        <div className="new-exclusive-item-access new-avenir-medium-log-cabin-20px" style={{display: "flex", justifyContent: "center"}}>
                          {exclusiveItemAccess}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="new-group-248-res"
                    style={{ backgroundImage: `url(${group249})`, backgroundRepeat:"no-repeat",backgroundSize: "70% 90%"  }}
                  >
                    <div className={classCouponds}>{itemValue}</div>
                  </div>
                  <ButtonWithdrawExclusiveEarning />
                  <div className="new-redeem-in-cart-res new-avenir-heavyoblique-normal-eerie-black-14px">
                    {redeemInCart1}
                  </div>
                </div>
              </div>

            </div>
            <div className="points-progress-bar-res">
              {pointsProgressBar1Props.children}
            </div>
            <div className="recent-activity-container">
              <div className="progress-container">
                <div className="bonus-rewads-res bonus-rewads-res-separation">
                  <div className="bonus-rewads-res-title">
                    <div>bonus rewards</div>
                    <div>${valCountry}</div>
                  </div>
                  <div>
                    <div className="bonus-image-left">
                      <img
                        className="new-path-64-2-res"
                        src="/img/new-path-64-1@1x.png"
                      />
                      <div className="new-path-container-1-res">
                        <img
                          className="new-path-51-1-res"
                          src="/img/new-path-51-2@1x.png"
                        />
                        <img
                          className="new-path-52-1-res"
                          src="/img/new-path-52-2@1x.png"
                        />
                        <img
                          className="new-path-53-1-res"
                          src="/img/new-path-53-2@1x.png"
                        />
                        <img
                          className="new-path-54-1-res"
                          src="/img/new-path-54-2@1x.png"
                        />
                      </div>
                    </div>
                    <div className="progress-bar-bonus">
                      <div className="progress-bar-content-bonus">
                        $
                        {Math.round(
                          valCountry * (lastsix / valCountry - Math.trunc(lastsix / valCountry))
                        )}
                      </div>
                      <div
                        className="new-barra-2 progress-bar-bonus background-progress"
                        max={valCountry}
                        value={bonusPoints}
                        role="progressbar" 
                        style={{
                          width: `${
                            (Math.round(
                              valCountry * (lastsix / valCountry - Math.trunc(lastsix / valCountry))
                            ) *
                              100) /
                              valCountry
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="bonus-rewads-res-desc">
                    $
                    {valCountry -
                      Math.round(
                        valCountry * (lastsix / valCountry - Math.trunc(lastsix / valCountry))
                      )}{" "}
                    more to 10 bonus points, 50% off item, 1 exclusive
                  </div>
                </div>

                {/* Validacion stylist  */}
                
                {checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                  <div className="tracking-rewards-res bonus-rewads-res-separation">
                    <div className="bonus-rewads-res-title">
                      <div>stylist rewards</div>
                      <div>${valCountry}</div>
                    </div>
                    <div>
                      <div className="progress-bar-bonus">
                        <div className="bonus-image-start">
                          <img className="new-path-64-res" src={path641} />
                          <img className="new-icon-star-res" src={iconStar} />
                        </div>
                        <div className="progress-bar-content-bonus stylist-progress-content">
                          lifetime member
                        </div>
                        <div
                          className="new-barra-2 progress-bar-bonus background-progress"
                          // max={200}
                          max={valCountry}
                          value={bonusPoints}
                          role="progressbar"
                          style={{ width: `100%` }}
                          // style={{ width: 200 }}

                        ></div>
                      </div>
                    </div>
                    <div className="bonus-rewads-res-desc">
                      You always earn 10% rewards
                    </div>
                  </div>
                )}

                {/* Validacion affiliate  */}
                {checkCustomer === "affiliate" && (
                  <div className="tracking-rewards-res bonus-rewads-res-separation">
                    <div className="bonus-rewads-res-title">
                      <div>track to affiliate</div>
                      <div>${valCountry}</div>
                    </div>
                    <div>
                      <div className="progress-bar-bonus">
                        <div className="bonus-image-start">
                          <img className="new-path-64-res" src={path641} />
                          <img className="new-icon-star-res" src={iconStar} />
                        </div>
                        <div className="progress-bar-content-bonus affiliate-progress">
                          Congrats, you did it!
                        </div>
                        <div
                          className="new-barra-2 progress-bar-bonus background-progress"
                          max={valCountry}
                          // max={200}
                          value={bonusPoints}
                          role="progressbar"
                          style={{ width: `100%` }}
                          // style={{ width: 200 }}

                        ></div>
                      </div>
                    </div>
                    <div className="bonus-rewads-res-desc">
                      you're an affiliate until{" "}
                      {`${monthAff}/${dayAff}/${yearAff}`}
                    </div>
                  </div>
                )}

                {/* Validacion customer  */}
                {checkCustomer === "customer" && (
                  <div className="tracking-rewards-res bonus-rewads-res-separation">
                    <div className="bonus-rewads-res-title">
                      <div>track to affiliate</div>
                      <div>${valCountry}</div>
                    </div>
                    <div>
                      <div className="progress-bar-bonus">
                        <div className="bonus-image-start">
                          <img className="new-path-64-res" src={path641} />
                          <img className="new-icon-star-res" src={iconStar} />
                        </div>
                        <div className="progress-bar-content-bonus">
                          $
                          {Math.round(
                            valCountry * (lastsix / valCountry - Math.trunc(lastsix / valCountry))
                          )}
                          {/* {Math.round(
                            200 * (lastsix / 200 - Math.trunc(lastsix / 200))
                          )} */}
                        </div>
                        <div
                          className="new-barra-2 progress-bar-bonus background-progress"
                          max={valCountry}
                          value={bonusPoints}
                          role="progressbar"
                          style={{width:`${
                            (Math.round(
                              valCountry * (lastsix / valCountry - Math.trunc(lastsix / valCountry))
                            ) *
                              100) /
                              valCountry
                          }%`}}
                        ></div>
                      </div>
                    </div>
                    <div className="bonus-rewads-res-desc">
                      ${
                        lastsix < valCountry ? (Math.trunc(valCountry-lastsix)):(Math.trunc(valCountry))
                      } more to become an affiliate, 50% off item, 1 exclusive
                    </div>
                  </div>
                )}

                <div className="possebox-rewards-res bonus-rewads-res-separation posse-height">
                  <div className="bonus-rewads-res-title">
                    <div>posse box</div>
                  </div>
                  <div className="months-container">
                    <div className="months-posse-box months-posse-box-3">
                      3 MONTHS
                    </div>
                    <div className="months-posse-box">6 MONTHS</div>
                    <div className="months-posse-box">9 MONTHS</div>
                    <div className="months-posse-box">12 MONTHS</div>
                  </div>
                  <div>
                    <div className="progress-bar-bonus posse-height-progress">
                      <div className="container-gift-client">
                        <img className="new-path-64-res-gift" src={path641} />
                        <img className="new-icon-gift-res" src={iconGift1} />
                      </div>
                      <div className="progress-bar-content-bonus posse-height-progress flex-months">
                        <div className="months-posse months-posse-20">
                          {
                            valCountry===250 ? "30 points" :"20 points"
                          }
                        </div>
                        <div className="months-posse">{valCountry===250 ? "40 points":"30 points"}</div>
                        <div className="months-posse">{valCountry===250 ? "70 points":"50 points"}</div>
                        <div className="months-posse">{valCountry===250 ? "100 points + gift":"70 points + gift"}</div>
                      </div>
                      <div
                        className="new-barra-2 progress-bar-bonus background-progress posse-height-progress posse-margin"
                        max={valCountry}
                        value={bonusPoints}
                        role="progressbar"
                        style={{
                          width: `${(susbs)<12 ? 
                            ((susbs*100)/12):
                            (((susbs % 12)*100)/12) }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="bonus-rewads-res-desc"> 
                    {susbs} consecutive months on Posse
                  </div>
                </div>
              </div>
              {datos && (
                <RecentActivity 
                  group1031Props={recentActivityProps.group1031Props}
                  group1032Props={recentActivityProps.group1032Props}
                  group98Props={recentActivityProps.group98Props}
                  dateProps={recentActivityProps.dateProps}
                  email={email}
                  idUser={idUser}
                  datos={datos}
                  lastsix={lastsix}
                  customerType={customerType}
                  dataAffiliate={dataAffiliate}
                />
              )}
            </div>
            {/* Modal Compartir */}
            <div className="App">
              <Modal
                isOpen={modalIsOpen}
                style={{width:"100%"}}
                onRequestClose={() => setIsOpen(false)}
                overlayClassName={{
                  base: "overlay-base",
                  afterOpen: "overlay-after more-index-plus",
                  beforeClose: "overlay-before",
                }}
                className={{
                  base: "content-base",
                  afterOpen: "content-after-max content-after-max-check",
                  beforeClose: "content-before",
                }}
                closeTimeoutMS={500}
              >
                <div className="show-generate-cart">
                  <div className="show-generate-cart-title">
                    SHARE
                  </div>
                  <div className="buttons-generated">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=plunderdesign.com/${userName}`}> 
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="fb" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <FacebookSharpIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Facebook
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`mailto:info@example.com?&subject=&cc=&bcc=&body=plunderdesign.com/${userName}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="email" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <EmailIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Email
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`http://instagram.com/_u/USERNAME/plunderdesign.com/${userName}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar  aria-label="ig" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <InstagramIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Instagram
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`sms:+1?body=plunderdesign.com/${userName}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <SmsIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  SMS
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://t.me/share?url=plunderdesign.com/${userName}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <TelegramIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Telegram
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <a className="linkEnlace" target="_blank" href={`https://pinterest.com/pin/create/button/?url=plunderdesign.com/${userName}`}>
                            <div style={{flexDirection:"column", display:"flex", alignItems:"center", justifyContent:"center"}}>
                              <Avatar aria-label="google" sx={{ width: 65, height: 65,bgcolor:'#f2dedb' }}>
                                <PinterestIcon sx={{ fontSize: 45 }} />
                              </Avatar>
                              <div style={{marginTop:5, display:"flex", justifyContent:"center"}}>
                                <h6 className="labelCopy">
                                  Pinterest
                                </h6>
                              </div>
                            </div>
                          </a>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <div className="contenedorLink">

                    <input className="inputCopy"  value={`plunderdesign.com/${userName}`} ></input>
                    <button type="button" className="buttonCopy" onClick={() => {
                        navigator.clipboard
                          .writeText(`plunderdesign.com/${userName}`)
                          .then(() => {
                            setCopiado(true);
                            console.log("Text copied to clipboard...");
                          })
                          .catch((err) => {
                            setCopiado(false);
                            console.log("Something went wrong", err);
                          });
                      }}>{copiado ? "COPIED " : "COPY LINK"}</button>
                  </div>
                </div>
                <button
                  className="close-modal"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <a className="text-close-modal">X</a>
                </button>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default DesktopHomeStylistDashboard;
