import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RecentActivity from "../NewRecentActivity Res";
import Group73 from "../NewGroup73";
import PointsProgressBar from "../NewPointsProgressBar";
import ButtonWithdrawAllEarning from "../NewButtonWithdrawAllEarning";
import ButtonWithdrawCouponEarning from "../NewButtonWithdrawCouponEarning";
import ButtonWithdrawExclusiveEarning from "../NewButtonWithdrawExclusiveEarning";
import Dashboard1 from "../NewDashboard1";
import RewardsReports from "../NewRewardsReports";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// import datatables 
import { DataGrid, GridToolbar,gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';



// import datapicker 

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';


// ===========

import "./DesktopHomeStylistDashboard.css";
import { width } from "@mui/system";

//select tags
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  'customer',
  'affiliate',
  'stylist'
];
// ==================

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height:800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function NewDesktopDashboardReports(props) {
  const [modalOrder, setmodalOrder] = useState(false);
  const handleOpen = () => setmodalOrder(true);
  const handleClose = () => setmodalOrder(false);

  const columns = [
    {field: 'id', headerName: 'MEMBER ID', width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center', },
    {field: 'name', headerName: 'NAME', width: 200, headerClassName: 'super-app-theme--header', headerAlign: 'center', },
    {field: 'memberType', headerName: 'MEMBER TYPE', width: 200, headerClassName: 'super-app-theme--header', headerAlign: 'center', },
    {field: 'linked',headerName: 'LINKED TO',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center',},
    {field: 'lastOrder',headerName: 'LAST ORDER DATE',width: 150, headerClassName: 'super-app-theme--header',headerAlign: 'center',},
    {field: 'lastOrderAmount',headerName: 'LAST ORDER AMOUNT',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center'},
    {field: 'avaliablePoints', headerName: 'AVALIABLE POINTS', width: 100, headerClassName: 'super-app-theme--header' ,headerAlign: 'center'},
    {field: 'lifeTimePoints', headerName: 'LIFETIME POINTS', width: 100, headerClassName: 'super-app-theme--header' ,headerAlign: 'center'},
    {field: 'nxpointExpDate', headerName: 'NEXT POINTS EXP DATE', width: 200, headerClassName: 'super-app-theme--header' ,headerAlign: 'center'},
    {field: 'toNextBonus',headerName: '$ TO NEXT BONUS REWARDS',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center'},
    {field: 'lastLinkedCustomer',headerName: 'LAST LINKED CUSTOMER ORDER DATE',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center'},
    {field: 'totalPointsEarned',headerName: 'TOTAL POINTS EARNED LAST 30 DAYS',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center'},
    {field: 'totalPointsEarned2',headerName: 'TOTAL POINTS EARNED LAST 180 DAYS',width: 100, headerClassName: 'super-app-theme--header',headerAlign: 'center'},
  ];

  const columnsOrders = [
    {field: 'id', headerName: 'ORDER ID', width: 100, headerClassName: 'super-app-theme--header' },
    {field: 'orderDate', headerName: 'ORDER DATE', width: 200, headerClassName: 'super-app-theme--header' },
    {field: 'nameOrder', headerName: 'NAME ON ORDER', width: 300, headerClassName: 'super-app-theme--header' },
    {field: 'orderAmount',headerName: 'ORDER AMOUNT',width: 225, headerClassName: 'super-app-theme--header'},
    {field: 'personalPoints',headerName: 'PERSONAL POINTS EARNED',width: 250, headerClassName: 'super-app-theme--header'},
    {field: 'pointsExpDate',headerName: 'POINTS EXP DATE',width: 200, headerClassName: 'super-app-theme--header'},
    {field: 'linkedTo', headerName: 'LINKED TO POINTS EARNED', width: 250, headerClassName: 'super-app-theme--header' },
    
  ];
  
  
  Date.isLeapYear = function (year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };
  Date.getDaysInMonth = function (year, month) {
    return [
      31,
      Date.isLeapYear(year) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ][month];
  };
  Date.prototype.isLeapYear = function () {
    var y = this.getFullYear();
    return (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
  };
  Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
  };
  Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
  };

  let email = params.email;
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState();
  const [customerType, setCustomerType] = useState();
  const [open, setOpen] = useState(false);
  const [idUser, setIdUser] = useState();
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonusPointsT, setBonusPointsT] = useState(0);
  const [points, setPoints] = useState(0);
  const [lastsix, setLastSix] = useState();
  const [paymentComplete, setPaymentComplete] = useState(0);
  const [datos, setDatos] = useState([]);
  const [userName, setUserName] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [checkCustomer, setCheckCustomer] = useState("");
  const [dataAffiliate, setDataAffiliate] = useState("");

  const [showHamburger, setShowHamburger] = useState(true);

  const {
    group252,
    activityLogs1,
    path641,
    iconStar,
    lifetimeMember,
    price,
    youAlwaysEarn10Rewards,
    group253,
    activityLogs2,
    group254,
    iconGift1,
    path642,
    iconGift2,
    x20Points,
    x30Points,
    x50Points,
    address1,
    address2,
    address3,
    address4,
    x75PointsGift,
    spanText1,
    spanText2,
    overlapGroup1,
    exclusiveItemAccess,
    number1,
    group249,
    overlapGroup2,
    x50OffCoupons,
    group248,
    number2,
    redeemInCart1,
    availablePoints,
    number3,
    group246,
    redeemInCart2,
    path51,
    path52,
    path53,
    path54,
    bonusRewards,
    forEvery200InPurchases,
    learnMore1,
    group239,
    percent,
    always10Per1Spent,
    onPersonalReferr,
    learnMore2,
    dashboard1,
    proposal,
    projects1,
    iconUser,
    projects2,
    group281,
    projects3,
    group292,
    projects4,
    group284,
    projects5,
    plunderBlackLogo250,
    title,
    welcome,
    activityLogs3,
    plunderdesignComXxxxxxx,
    dashboard2,
    recentActivityProps,
    pointsProgressBar1Props,
    buttonWithdrawAllEarningProps,
    pointsProgressBar2Props,
    dashboard1Props,
    group82Props,
  } = props;

  const emailLS = localStorage.getItem("correo_usuario");

  const [country,setcountry]=useState("");
  const [valCountry,setvalCountry]=useState(200);
  const [estadoBackOffice,setestadoBackOffice]=useState(true);
  const [estadoPersonalizeOrder,setestadoPersonalizeOrder]=useState(true);

  if (!email && emailLS) email = emailLS;

  const data = { email: email };

  useEffect(() => {
    // setLoading(true);
    // setOpen(true);
    const datosUsuario=JSON.parse(localStorage.getItem("infoUsuario"))
    setFirstName(datosUsuario.fullName);
    setCustomerType(localStorage.getItem("customerType"));
    setIdUser(datosUsuario.id);
    
  }, []);


  // DATA TABLES
  // const StyledTableCell = styled(TableCell)(({ theme }) => ({
  const [rows,setrows]=useState([]);
  const [rowsAux,setrowsAux]=useState([]);
  const [rowsOrder,setrowsOrder]=useState([]);
  const [rowsOrderAux,setrowsOrderAux]=useState([]);


  const [verTabla,setverTabla]=useState(false);
  const [verTablaOrder,setverTablaOrder]=useState(false);

  const [arrOrderId,setarrOrderId]=useState([]);

  // cabecera de la ordern
  const [fecha,setHeadfecha]=useState();
  const [nombres,setHeadnombres]=useState();
  const [direccion,setHeaddireccion]=useState();
  const [provincia,setHeadprovincia]=useState();
  const [ciudad,setHeadciudad]=useState();
  const [codigopostal,setHeadcodigopostal]=useState();
  const [pais,setHeadpais]=useState();
  const [correo,setHeadcorreo]=useState();
  const [ordersCom,setHeadordersCom]=useState();
  const [metodoPago,setHeadmetodoPago]=useState();
  const [shipped,setHeadshipped]=useState();

  // items de la orden
  const [itemsOrderList,setitemsOrderList]=useState([]);
  // footer de la orden 
  const [itemsSubtotal,setitemsSubtotal]=useState();
  const [shippingRate,setshippingRate]=useState();
  const [taxes,settaxes]=useState();
  const [descuentoOrder,setdescuentoOrder]=useState();
  const [totalOrderF,settotalOrderF]=useState();



  const verDetalleOrder=(orderId) => {
    try {
      var response=orderId;
      if(orderId.length>7){
        // orderId.slice(1);
        var cadena2 = orderId;
        var primer = orderId.charAt(0);
        var siguiente = cadena2.slice(2);
        response = `${primer}${siguiente}`;
      }
      const options = {
        method: 'GET',
        headers: {accept: 'application/json', Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`}
      };
      fetch(`https://app.ecwid.com/api/v3/78338716/orders/${response}`, options)
      .then(response => response.json())
      .then(response => {
        console.log("Data detalle order id: ");
        if(response != null || response != "" || response != undefined ){
          setarrOrderId(response.id);
          // datos cabecera de la orden
          var fechaAux= response.createDate;
          var arrFecha= fechaAux.split(" ");
          var convrDate = new Date(arrFecha[0]);
          var formatoFecha = convrDate.toDateString();
          // console.log("formato fecha nuevo: ",formatoFecha +" "+arrFecha[1]);
          
          setHeadfecha(formatoFecha +" "+arrFecha[1]);
          setHeadnombres(response.shippingPerson.name);
          setHeaddireccion(response.shippingPerson.street);
          setHeadprovincia(response.shippingPerson.stateOrProvinceName);
          setHeadciudad(response.shippingPerson.city);
          setHeadcodigopostal(response.shippingPerson.postalCode);
          setHeadpais(response.shippingPerson.countryName);
          setHeadcorreo(response.email);
          setHeadordersCom(response.orderComments);
          setHeadmetodoPago(response.paymentMethod);
          setHeadshipped(response.fulfillmentStatus);
          // items 
          setitemsOrderList(response.items);
          // footer oreder 
          setitemsSubtotal(Number.parseFloat(response.subtotal).toFixed(2));
          setshippingRate(Number.parseFloat(response.shippingOption.shippingRate).toFixed(2));
          settaxes(Number.parseFloat(response.customSurcharges[0].value).toFixed(2));
          setdescuentoOrder(Number.parseFloat(response.discount).toFixed(2));
          settotalOrderF(Number.parseFloat(response.total).toFixed(2));      
        }
      })
      .catch(err => console.error(err));
    } catch (error) {
      console.log("paso algo", error);
    }
  }

  useEffect(() => {
    try {
      setLoading(true);
      setOpen(true);
      if(idUser=== undefined || idUser===null || idUser==="" ){
        console.log("el usuario es undefined tabla 1");
        // setverTabla(false);
      }else{
        console.log("tabla 1 ID USUARIO ENTRO AL ELSE ",idUser);
        settablememberActivity(true);
        let options = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nodeId: idUser,
          }),

        };
        fetch("https://deploywk.herokuapp.com/plunder/stylist-reports/", options)
          .then((res) => res.json())
          .then((res) => {
            console.log("res.length: ",res.length);
            if(res.length === 0){
              setrows([]);
              setrowsAux([]);
              console.log("no hay data para mostrar porque el dateset es = 0");
              setverTabla(true);
            }else{
              const dataset=JSON.parse(res);
              console.log("este es el datast convertido tabla 1: ",dataset);
              dataset.forEach(element => {
                const nombreLinked= element.Sponsor===""? firstName:element.Sponsor;
                let lastpoints180d=element.last_180days_points;
                let lastpoints_180d = Math.round(200 * (lastpoints180d / 200 - Math.trunc(lastpoints180d / 200)));
                let lastorderamount=element.last_order_amount;
                lastorderamount=parseFloat(lastorderamount).toFixed(2);
                rows.push({ id:element.id , name:element.fullName , memberType:element.customerType , linked:nombreLinked , lastOrder:element.last_order_date , lastOrderAmount:lastorderamount , avaliablePoints:element.available_points , lifeTimePoints:element.total_points , nxpointExpDate:element.expire_date , toNextBonus:lastpoints_180d , lastLinkedCustomer:'' ,  totalPointsEarned:element.last_30days_points , totalPointsEarned2:element.last_180days_points });
                rowsAux.push({ id:element.id , name:element.fullName , memberType:element.customerType , linked:nombreLinked , lastOrder:element.last_order_date , lastOrderAmount:lastorderamount , avaliablePoints:element.available_points , lifeTimePoints:element.total_points , nxpointExpDate:element.expire_date , toNextBonus:lastpoints_180d , lastLinkedCustomer:'' ,  totalPointsEarned:element.last_30days_points , totalPointsEarned2:element.last_180days_points });
              });
              setverTabla(true);
              setLoading(false);
              setOpen(false);
            }
          });  
      }
    } catch (error) {
      setrows([]);
      setrowsAux([]);
      console.log("paso algo", error);
      setverTabla(false);
    }
    
  }, [idUser]);

  useEffect(() => {
    try {
      if(idUser=== undefined || idUser===null || idUser==="" ){
        console.log("el usuario es undefined tabla 2");
      }else{
        settablememberActivity(true);
        let options = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nodeId: idUser,
          }),
        };
        fetch("https://deploywk.herokuapp.com/plunder/stylist-order-reports/", options)
          .then((res) => res.json())
          .then((res) => {
            if(res.length === 0){
              setrowsOrder([]);
              setrowsOrderAux([]);
              // setverTablaOrder(true);
            }else{
              const dataset=JSON.parse(res);
              dataset.forEach(element => {
                var dateExpDate = new Date(element.POINTS_EXP_DATE);
                dateExpDate=dateExpDate.getDate()+
                "/"+(dateExpDate.getMonth()+1)+
                "/"+dateExpDate.getFullYear();

                var dateOrder = new Date(element.ORDER_DATE);
                dateOrder=(dateOrder.getMonth()+1)+
                "/"+dateOrder.getDate()+
                "/"+dateOrder.getFullYear();

                let linkedTo_=Math.trunc(element.LINKED_TO_POINTS_EARNED);
                rowsOrder.push({ id:element.ORDER_ID , orderDate:dateOrder , nameOrder:element.NAME_ON_ORDER,orderAmount: element.ORDER_AMOUNT, personalPoints:element.PERSONAL_POINTS_EARNED , pointsExpDate:dateExpDate,linkedTo:linkedTo_ });
                rowsOrderAux.push({ id:element.ORDER_ID , orderDate:dateOrder , nameOrder:element.NAME_ON_ORDER,orderAmount: element.ORDER_AMOUNT, personalPoints:element.PERSONAL_POINTS_EARNED , pointsExpDate:dateExpDate,linkedTo:linkedTo_ });
                // setrowsOrder([...rowsOrder,{ id:element.ORDER_ID , orderDate:dateOrder , nameOrder:element.NAME_ON_ORDER,orderAmount: element.ORDER_AMOUNT, personalPoints:element.PERSONAL_POINTS_EARNED , pointsExpDate:dateExpDate,linkedTo:linkedTo_ }]);
              });
              // setverTablaOrder(true);
              
            }
          });  
      }
    } catch (error) {
      
      console.log("paso algo", error);
      setrowsOrder([]);
      setverTablaOrder(false);
    }
    
  }, [idUser]);

  // ============== TABS ===============
  const [memberActivity,setmemberActivity]=useState(true);
  const [orderActivity,setorderActivity]=useState(true);
  const [tablememberActivity,settablememberActivity]=useState(true);
  const [tableorderActivity,settableorderActivity]=useState(false);

  // ===================================


  const linkto = ()=>{
    window.open(`https://authworkinglive.com/auth/loginv3?email=${email.toLowerCase()}`,'_blank');
  }
  


  // filtrado general de cliente member activity
  const [nameCliente, setNameCliente] = useState(null);
  const [contador, setcontador]=useState(1);
  const [datafilter,setdatafilter]=useState(null);
  const [newRows, setNewRows]=useState([]);

  const queryClient= e=>{setNameCliente(e.target.value);console.log(e.target.value);}

  useEffect(()=>{

    if(nameCliente===null||nameCliente===""){
      console.log("input vacio");
      console.log("rowAux: ", rowsAux);
      console.log("rows: ", rows);


    }else{
      if(tablememberActivity){
        filtroGeneral();
      }
      if(tableorderActivity){
        filtroGeneralOrderAct();
      }
    }
  },[nameCliente]);

  function filtroGeneral(){
    console.log("================================");
    console.log("entra al filtro general");
    let rowArray = [];
    console.log(nameCliente);
    let query=nameCliente.toUpperCase();
    rowsAux.forEach(item => {
      let isArray = false;
      for (let key in item) {
        let col = item[key];
        if(typeof col != 'object' && typeof col != 'boolean'&& typeof col !='number' && typeof col != 'undefined'){
          let cols = col.toUpperCase();
          if(cols.includes(query)){
            isArray = true;
            setcontador(contador+1);
          }
        }
      }
      if(isArray === true){
        rowArray.push(item.id);
      }
    });
    setdatafilter(rowArray);
    if(rowArray.length === 0){
      setcontador(0);
    }
    let result=pasarData(rowArray);
    console.log("Array result: ",result);
    setrows(result);
    return true;
  }

  function pasarData(datafilter){
    var auxRowM=[];
    if(nameCliente){
      setrows([]);
      rowsAux.forEach(element => {
        // setrows([]);
        let idClient= element.id;
        if(datafilter!=null){
          let index_r=datafilter.indexOf(idClient);
          if(index_r>=0){
            const nombreLinked= element.Sponsor===""? firstName:element.Sponsor;
            let lastpoints180d=element.last_180days_points;
            let lastpoints_180d = Math.round(200 * (lastpoints180d / 200 - Math.trunc(lastpoints180d / 200)));
            let lastorderamount=element.last_order_amount;
            lastorderamount=parseFloat(lastorderamount).toFixed(2);
            console.log("Pasar data: ",element);
            auxRowM.push(element);
            console.log("datos insertados previo al return: ",auxRowM);
          }
        }
      });
    }else{
      auxRowM=rowsAux;
    }
    return auxRowM;
  }

  function filtroGeneralOrderAct(){
    console.log("================================");
    console.log("entra al filtro general order");
    let rowArray = [];
    console.log(nameCliente);
    let query=nameCliente.toUpperCase();
    rowsOrderAux.forEach(item => {
      let isArray = false;
      for (let key in item) {
        let col = item[key];
        if(typeof col != 'object' && typeof col != 'boolean'&& typeof col !='number' && typeof col != 'undefined'){
          let cols = col.toUpperCase();
          if(cols.includes(query)){
            isArray = true;
            setcontador(contador+1);
          }
        }
      }
      if(isArray === true){
        rowArray.push(item.id);
      }
    });
    setdatafilter(rowArray);
    if(rowArray.length === 0){
      setcontador(0);
    }
    console.log("id found: ",rowArray);
    let result=pasarDataOrder(rowArray);
    console.log("Array result: ",result);
    setrowsOrder(result);
    return true;
  }

  function pasarDataOrder(datafilter){
    var auxRowM=[];
    if(nameCliente){
      setrowsOrder([]);
      rowsOrderAux.forEach(element => {
        // setrows([]);
        let idClient= element.id;
        if(datafilter!=null){
          let index_r=datafilter.indexOf(idClient);
          if(index_r>=0){
            console.log("Pasar data: ",element);
            auxRowM.push(element);
            console.log("datos insertados order activity previo al return: ",auxRowM);
          }
        }
      });
    }else{
      auxRowM=rowsOrder;
    }
    return auxRowM;
  }

  // filtrado fechas
  const [dateIn, setDateIn]=useState(null);
  const [dateMin, setDateMin]=useState(null);
  const [dateFin, setDateFin]=useState(null);
  const changeDateIn=(e)=>{setDateIn(e.target.value);setDateMin(e.target.value);console.log("fecha inicio: ",e.target.value);}
  const changeDateFin=(e)=>{setDateFin(e.target.value);searchDateRange(dateIn,e.target.value);}

  // filtrarpor rango de fechas en member activity
  const searchDateRange=(fechaInicio,fechaFin)=>{
    let newArr=[];
    // variables usadas para member activity 
    let arr=rowsAux;
    // ==================
    // varibles para orders activity 
    let arrOr=rowsOrderAux;

    let fInicio=new Date(fechaInicio);
    let fFin= new Date(fechaFin);
    if(tablememberActivity){
      arr.forEach(element => {
        let fechaAux= element.lastOrder.split('T');
        let fechaArray= new Date(fechaAux[0]);
        if(fechaArray.getTime() >= fInicio.getTime() && fechaArray.getTime() <= fFin.getTime()){
          console.log("array fechas");
          console.log(newArr);
          newArr.push(element);
        }
      });
      setrows(newArr);
    }else{
      arrOr.forEach(element => {
        var fechaAux= new Date(element.orderDate);

        var day= fechaAux.getDate();
        var month = fechaAux.getMonth() + 1;
        var year = fechaAux.getFullYear();

        var dayIn= fInicio.getDate();
        var monthIn = fInicio.getMonth() + 1;
        var yearIn = fInicio.getFullYear();

        var dayFn= fFin.getDate();
        var monthFn = fFin.getMonth() + 1;
        var yearFn = fFin.getFullYear();


        var newDateArr=`${year}/${month}/${day}`;
        var newDateArrIn=`${yearIn}/${monthIn}/${dayIn}`;
        var newDateArrFn=`${yearFn}/${monthFn}/${dayFn}`;

        let fechaArray= new Date(newDateArr);
        let fechaArrayIn= new Date(newDateArrIn);
        let fechaArrayFn= new Date(newDateArrFn);

        console.log("fecha order activity: ",fechaArray);
        console.log("fecha inicio ",fechaArrayIn.getTime());
        console.log("fecha fin ",fechaArrayFn.getTime());
        console.log("nuevo formato de fecha: ",fechaArray.getTime());
        if(fechaArray.getTime() >= fechaArrayIn.getTime() && fechaArray.getTime() <= fechaArrayFn.getTime()){
          newArr.push(element);
        }
      });
      setrowsOrder(newArr);
    }
    console.log("coincide con el rango: ",newArr);
    
  }

  // filtrar por tipo cliente 
  const [personType, setpersonType] = useState([]);
  const selectType = (event) => {
    const {target: { value },} = event;
    setpersonType(typeof value === 'string' ? value.split(',') : value,
    );
    console.log(value);
  };

  const filtrarTipoCli=()=>{
    let newArr=[];
    let arrAux=[];
    if(tablememberActivity){
      if(dateFin==null && (nameCliente==null || nameCliente =="")){
        arrAux=rowsAux;  
      }else{
        arrAux=rows;
      }
      arrAux.forEach(element => {
        let tipo=String(element.memberType);
        console.log("patron original: ",element.memberType);
        console.log("existe: ",personType.includes(tipo));
        if(personType.includes(tipo)){
          console.log("entra a la condicion");
          newArr.push(element);
        }
      });
      console.log("nuevo filtrado: ",newArr);
      setrows(newArr);
    }
  }

  //limpiar filtros
  const cleanFilter = ()=>{
    setrows(rowsAux);
    setrowsOrder(rowsOrderAux);
  }

  const [orderIdNumber,setOrderId]=useState();

  return (
    <>
      <div>
        {isLoading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </div>

      {!isLoading && (
        <div className="new-overlap-group9-res">
          <div
            className="show-hamburger-menu"
            onClick={() => {
              setShowHamburger(!showHamburger);
            }}
          >
            <a>
              <img
                width={25}
                height={25}
                src="https://i.imgur.com/I8jdeMN.png"
              ></img>
            </a>
          </div>

          <div hidden={showHamburger} className="new-sidebar1-res">
            <div className="new-group-112-res">
              <div className="">
                <div className="new-group-268">
                  <div className="new-overlap-group-5">
                    <img
                      className="new-plunder-black-logo-250"
                      src={plunderBlackLogo250}
                    />
                    <h1 className="new-title new-brittanysignature-regular-normal-geyser-48px">
                      {title}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="new-menus-res new-avenir-medium-black-18px">
              <Link
                to="/desktop-dashboard"
                className="none-decoration"
              >
                <div className="new-x01 without-background">
                  <div className="new-group-300">
                    <Dashboard1 dashboard1={dashboard1Props.dashboard1} />
                    <div className="new-dashboard new-avenir-medium-cod-gray-18px">
                      Dashboard
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/desktop-my-account" className="none-decoration">
                <div className="new-group-83">
                  <div className="new-group-82">
                    <img className="new-icon-user" src={iconUser} />
                    <div className="new-projects-1 new-avenir-medium-black-18px">
                      {projects2}
                    </div>
                  </div>
                </div>
              </Link>
              {
                estadoPersonalizeOrder&&(
                  <a className="none-decoration">
                    <div className="new-x02" onClick={()=>linkto()}>
                      <div className="new-group-301">
                        <div
                          className="new-proposal"
                          style={{ backgroundImage: `url(${proposal})` }}
                        ></div>
                        <div className="new-projects">Personalize Order</div>
                      </div>
                    </div>
                  </a>
                )
              }
              {
                email == "jamie@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <>
                      <Link
                        to="/desktop-my-stylist-rewards-reports"
                        className="none-decoration"
                      >
                        <div className="new-group-84" style={{backgroundColor:"#F2DEDB"}}>
                          <div className="new-group-82-4-tables">
                            <div className="group-85">
                              <div className="new-path-container-3">
                                <img className="path-69-1" src="/img/new-path-69-10@1x.png" />
                                <img className="path-70-1" src="/img/new-path-70-10@1x.png" />
                              </div>
                            </div>
                            <div className="new-projects-5 new-avenir-medium-black-18px">My Rewards Reports</div>
                          </div>
                        </div>
                      </Link>
                      {memberActivity&&(<div
                        id="facebook-panel"
                        className="x01-4-default facebook-panel-1"
                        onClick={() => {
                          settablememberActivity(true);
                          settableorderActivity(false);
                          setverTabla(true);
                          setverTablaOrder(false);

                        }}
                        style={{backgroundColor: tablememberActivity&&('#F2DEDB')}}
                      >
                        <img
                          src="https://img.icons8.com/material-sharp/344/commercial-development-management.png"
                          className="facebook-image"
                        ></img>
                        <div className="dashboard-4-1 avenir-medium-cod-gray-18px facebook-text">
                        Member Activity 
                        </div>
                      </div>)
                      }
                      {
                        orderActivity&&(
                          <div
                        id="facebook-panel"
                        className="x01-4-default facebook-panel-1" style={{marginTop:"-2px", backgroundColor: tableorderActivity&&('#F2DEDB')}}
                        onClick={() => {
                          settablememberActivity(false);
                          setverTabla(false);
                          settableorderActivity(true);
                          setverTablaOrder(true);
                          


                        }}
                      >
                        <img
                          src="https://img.icons8.com/ios-filled/344/order-history.png"
                          className="facebook-image"
                        ></img>
                        <div className="dashboard-4-1 avenir-medium-cod-gray-18px facebook-text">
                        Order Activity
                        </div>
                      </div>
                        )
                      }
                    </> 
                  )

                )
              }
              {
                email=="tylortest10@plunderdesign.com" &&(
                  checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                    <>
                      <Link
                        to="/desktop-my-stylist-rewards-reports"
                        className="none-decoration"
                      >
                        <div className="new-group-84" style={{backgroundColor:"#F2DEDB"}}>
                          <div className="new-group-82-4-tables">
                            <div className="group-85">
                              <div className="new-path-container-3">
                                <img className="path-69-1" src="/img/new-path-69-10@1x.png" />
                                <img className="path-70-1" src="/img/new-path-70-10@1x.png" />
                              </div>
                            </div>
                            <div className="new-projects-5 new-avenir-medium-black-18px">My Rewards Reports</div>
                          </div>
                        </div>
                      </Link>
                      {
                      memberActivity&&(<div
                        id="facebook-panel"
                        className="x01-4-default facebook-panel-1"
                        onClick={() => {
                          settablememberActivity(true);
                          settableorderActivity(false);
                          setverTablaOrder(false);
                          setverTabla(true);

                        }}
                        style={{backgroundColor: tablememberActivity&&('#F2DEDB')}}
                      >
                        <img
                          src="https://img.icons8.com/material-sharp/344/commercial-development-management.png"
                          className="facebook-image"
                        ></img>
                        <div className="dashboard-4-1 avenir-medium-cod-gray-18px facebook-text">
                        Member Activity 
                        </div>
                      </div>)
                      }
                      {
                        orderActivity&&(
                          <div
                        id="facebook-panel"
                        className="x01-4-default facebook-panel-1" style={{marginTop:"-2px", backgroundColor: tableorderActivity&&('#F2DEDB')}}
                        onClick={() => {
                          settablememberActivity(false);
                          settableorderActivity(true);
                          setverTablaOrder(true)
                          setverTabla(false);


                        }}
                      >
                        <img
                          src="https://img.icons8.com/ios-filled/344/order-history.png"
                          className="facebook-image"
                        ></img>
                        <div className="dashboard-4-1 avenir-medium-cod-gray-18px facebook-text">
                        Order Activity
                        </div>
                      </div>
                        )
                      }
                    </> 
                  )

                )
              }              
              {checkCustomer !== "customer" && checkCustomer !== "affiliate" && (
                <Link
                  to="/desktop-create-a-cart-stylist-only"
                  className="none-decoration"
                >
                  <div className="new-group-84-1">
                    <div className="new-group-82-1">
                      <div className="new-group-302">
                        <div
                          className="new-group-281"
                          style={{ backgroundImage: `url(${group281})` }}
                        ></div>
                        <div className="new-projects-2">{projects3}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              )}
              {/* =================== My back office================= */}
              {checkCustomer !== "customer" && checkCustomer !== "affiliate"&& (
                estadoBackOffice &&(
                  <a className="none-decoration">
                    <div className="new-group-2" onClick={()=>linkto()} >
                      <div className="new-group-82-2">
                        <div className="new-group-303">
                          <div
                            className="new-group-292"
                            style={{ backgroundImage: `url(${group292})` }}
                          ></div>
                          <div className="new-projects-3">{projects4}</div>
                        </div>
                      </div>
                    </div>
                  </a>
                )
              )}
              
              <div className="new-group-2">
                <div className="new-group-304">
                  <div
                    className="new-group-284"
                    style={{ backgroundImage: `url(${group284})` }}
                  ></div>
                  <div className="new-group-82-3">
                    <div className="new-projects-4">{projects5}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-main-home"
            onClick={() => {
              setShowHamburger(true);
            }}
          > 
            
            <div style={{marginBottom:10,marginTop:10}}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={5} md={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={5} md={5}>
                        <div className="contenedorInputDate">
                          <input className="inputDate" type="date" id="dateStart" value={dateIn} onChange={changeDateIn} />
                        </div>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <div className="input-v1">
                          <p >to</p> 
                        </div>
                      </Grid>
                      <Grid item xs={5} md={5}>
                        <div className="contenedorInputDate">
                          <input className="inputDate" type="date" id="dateEnd" min={dateMin} value={dateFin} onChange={changeDateFin} />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    verTabla&&(
                      <Grid item xs={2} md={2}>
                        <FormControl size="small" sx={{width:'100%'}}>
                          <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={personType}
                            onChange={selectType}
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox checked={personType.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                            <div style={{display:'flex',justifyContent:'center', marginTop:'10px'}}>
                              <Button variant="contained" onClick={filtrarTipoCli} size="large">Submit</Button>
                            </div>
                          </Select>
                        </FormControl>
                      </Grid>
                    )
                  }
                  <Grid item xs={5} md={5}>
                    <div className="contenedorInputDate">
                      <input className="inputDate" type="text" placeholder="Search" value={nameCliente} onChange={queryClient} />
                    </div>
                  </Grid>
                  
                </Grid>
                <div className="filterData">
                  <button className="btnClearFilter" onClick={cleanFilter} >Clear</button>
                </div>
              </Box>
            </div>
            {
              verTabla &&(<div style={{ height: 500, width: '100%' }}>
                <Box sx={{
                  height: 500,
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: '#F2DEDB',
                    borderWidth:'1'
                  },
                }}>
                  <div style={{margin:5, fontFamily:'Avenir-Medium', letterSpacing:'1px', fontSize:'15px'}}>
                    <p>Click on a category name to sort by that column.</p>
                    {/* <p>Click on a Name for additional information about that account.</p> */}
                  </div>
                  <StripedDataGrid rows={rows} columns={columns} getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    } />                    

                </Box>
              </div>)
            }
            {
              verTablaOrder &&(
              <div style={{ height: 500, width: '100%' }}>
                <Box sx={{
                  height: 500,
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: '#F2DEDB',
                    borderWidth:'1',
                    
                  },
                }}>
                  <div style={{margin:5, fontFamily:'Avenir-Medium', letterSpacing:'1px', fontSize:'15px'}}>
                    <p>Click on a category name to sort by that column.</p>
                    {/* <p>Click on a Name for additional information about that account.</p> */}
                  </div>
                  <StripedDataGrid rows={rowsOrder} columns={columnsOrders} getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  } onRowClick={(params, event) => {
                    if (!event.ignore) {
                      console.log(
                        "push -> /roles/" + params.row.id,
                      );
                      verDetalleOrder(params.row.id)
                      setOrderId(params.row.id);
                      handleOpen();
                    }
                  }}  />

                </Box>
                <div>
                  <Modal
                    open={modalOrder}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      {
                        arrOrderId.length > 0 ? (
                          <div className="containerModal">
                            <h4 className="titleOrder" >PLUNDER</h4>
                            <div className="rowModal">
                              <div className="colModal">
                                <span className="subtitleOrder" ><strong>Plunder Design</strong></span>
                                <span className="textOrder" >Store78338716.workinglive.biz/products</span>
                                <span className="textOrder">Plunder Design</span>
                                <span className="textOrder">1374 American Way</span>
                                <span className="textOrder">Payson, Utah 84651</span>
                                <span className="textOrder">United States</span>
                              </div>
                              <div className="colModal">
                                <span className="textOrder"><strong>Customer Service</strong></span>
                                <span className="textOrder">+1 877-276-5627</span>
                                <span className="textOrder">support@plunderdesign.com</span>
                              </div>
                              
                            </div>
                            <Divider />
                            <span className="subtitleOrder">{fecha}</span>
                            <div className="rowModal">
                              <div className="colModal">
                                <span className="textOrder">{nombres} </span>
                                <span className="textOrder">{direccion}</span>
                                <span className="textOrder">{provincia}, {ciudad} {codigopostal}</span>
                                <span className="textOrder">{pais}</span>
                                <span className="textOrder">{correo}</span>
                                <span className="textOrder">Order comments: {ordersCom}</span>

                              </div>
                              <div className="colModal">
                                <span className="textOrder">Shipped: {shipped} </span>
                                <span className="textOrder">Payment: {metodoPago} </span>
                              </div>
                            </div>
                            <Divider />
                            <span className="subtitleOrder">Order #{arrOrderId}</span>
                            <div className="scrollItems">
                              {
                                itemsOrderList.map(element=>(
                                  <div className="rowModal-v2">
                                    <div className="colModal">
                                      <span className="textOrder">{element.name}</span>
                                      <span className="textOrder">SKU: {element.sku} </span>
                                      <span className="textOrder">Currency: {element.selectedOptions[0].value} </span>
                                    </div>
                                    <div className="colModalv2">
                                      <span className="textOrder">{element.quantity}</span>
                                    </div>
                                    <div className="colModalv2">
                                      <span className="textOrder">${Number.parseFloat(element.price).toFixed(2)}</span>
                                    </div>
                                  </div>
                                ))
                              }

                            </div>
                            <Divider />
                            <div className="rowModal-footer">
                              <div className="containderFooterOrder">
                                <div className="colFooter">
                                  <span className="textOrder">Items</span>
                                  <span className="textOrder">Shipping</span>
                                  <span className="textOrder">Tax</span>
                                  <span className="textOrder">Discount</span>
                                  <span className="textOrder">Total</span>
                                </div>
                                <div className="colFooter-v2">
                                  <span className="textOrder">${itemsSubtotal} </span>
                                  <span className="textOrder">${shippingRate}</span>
                                  <span className="textOrder">${taxes}</span>
                                  <span className="textOrder">${descuentoOrder}</span>
                                  <span className="textOrder">${totalOrderF}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ):(<h1 className="textOrder">Not Found</h1>) 
                      }
                    </Box>
                  </Modal>
                </div>
              </div>)
            }
            {/* <button onClick={()=>console.log(rows)} >view Data</button> */}
          </div>
        </div>
      )}
    </>
  );
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default NewDesktopDashboardReports;
