import React from "react";
import "./BACKGROUND.css";

function BACKGROUND() {
  return (
    <div className="background-2">
      <div className="rectangle-37"></div>
    </div>
  );
}

export default BACKGROUND;
