import React from "react";
import "./Group98.css";

function Group98(props) {
  const { className } = props;

  return (
    <div className={`group-98 ${className || ""}`}>
      <img className="new-icon-user-4" src="/img/new-icon-10@1x.png" />
    </div>
  );
}

export default Group98;
