import React from "react";
import "./Group86.css";

function Group86() {
  return (
    <div className="group-86">
      <div className="header-9">
        <img className="plunder-black-logo-250-5" src="/img/plunder-black-logo-250@1x.png" />
      </div>
    </div>
  );
}

export default Group86;
