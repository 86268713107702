import React, { useState, useEffect } from "react";
import Group103 from "../NewGroup103";
import Group98 from "../NewGroup98";
import DueDate from "../NewDueDate";
import DateActual from "../NewDate";
import "./RecentActivity.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function RecentActivity(props) {
  const [isLoading, setLoading] = useState();
  const [open, setOpen] = useState();
  const [points, setPoints] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);

  let count = 0;
  const currentDate = new Date().toString();
  const {
    email,
    idUser,
    className,
    group1031Props,
    group1032Props,
    group98Props,
    dateProps,
    datos,
    lastsix,
    customerType,
    dataAffiliate,
  } = props;

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"].length != 0) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "PlunderPoints") {
              setPoints(bonus["amount"] - bonus["released"]);
            }
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"] - bonus["released"]);
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(Math.trunc(bonus["amount"]));
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setDiscountValue(Math.trunc(bonus["amount"]));
              setReleasedVaule(Math.trunc(bonus["released"]));
            }
          });
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((res) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT 2.0");
        setLoading(false);
        setOpen(false);
      });
  }, []);

  return (
    <>
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {!isLoading && (
        <div className={`recent-activity ${className || ""}`}>
          <div className="group-250">
            <div className="schedule">
              <div className="new-title-1">
                <div className="schedule-1 new-avenir-black-eerie-black-24px">
                  Recent Activity
                </div>
                <div className="thursday-january-10th-2022 new-montserrat-medium-quick-silver-16px">
                  {currentDate.split(" ").slice(0, 4).join(" ")}
                </div>
              </div>
            </div>
            <div className="show-scroll-activity">
              {datos.map((data, index) => {
                count += 1;
                return (<Group103
                  dateProps={group1031Props.dateProps}
                  key={index}
                  data={data}
                  lastsix={lastsix}
                  customerType={customerType}
                  dataAffiliate={dataAffiliate}
                />)
              }
                // console.log("2=>",data);
                // if (
                //   (data["firstName"] &&
                //     data["customerType"] === "customer" &&
                //     count < 10) ||
                //   (localStorage.getItem("correo_usuario") === data["emailAddress"] &&
                //     count < 10)
                // ) {
                //   count += 1;
                //   return (
                //     <Group103
                //       dateProps={group1031Props.dateProps}
                //       key={index}
                //       data={data}
                //       lastsix={lastsix}
                //       customerType={customerType}
                //       dataAffiliate={dataAffiliate}
                //     />
                //   );
                // }
              )}
            </div>
            {!count && (
              <div className="group-250-item">
                <div className="new-overlap-group1-6">
                  <div className="group-102">
                    <div className="new-overlap-group-26">
                      <div className="meeting-with-client-2 new-avenir-black-eerie-black-16px font-size-noactivity">
                        NO RECENT ACTIVITY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="group-250-item">
              <Group98 />
              <div className="overlap-group2">
                <DueDate />
                <div className="group-97">
                  <div className="new-overlap-group-6">
                    <div className="meeting-with-client new-avenir-black-eerie-black-16px">
                      Pam Gould
                    </div>
                    <div className="date">
                      <div className="calendar"></div>
                      <div className="date-1 new-manrope-normal-quick-silver-10px">
                        January 17, 2021
                      </div>
                    </div>
                  </div>
                  <div className="x1000-1100 new-manrope-extra-bold-geyser-10px">
                    Customer Purchase $44
                  </div>
                </div>
              </div>
            </div>

            <div className="group-250-item">
              <Group98 className={group98Props.className} />
              <div className="new-overlap-group4-1">
                <DueDate />
                <div className="group-97-1">
                  <div className="new-overlap-group-7">
                    <div className="meeting-with-client new-avenir-black-eerie-black-16px">
                      Pam Gould
                    </div>
                    <DateActual />
                  </div>
                  <div className="x1000-1100-1 new-manrope-extra-bold-geyser-10px">
                    Customer Purchase $44
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default RecentActivity;
