import React, { useEffect, useState } from "react";
import ButtonWithdrawAllEarning2 from "../ButtonWithdrawAllEarning2";
import Group68 from "../Group68";
import Group103 from "../Group103";
import "./CONTENT.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function CONTENT(props) {
  const [isLoading, setLoading] = useState();
  const [open, setOpen] = useState(false);
  const [lastsix, setLastSix] = useState();
  const [datos, setDatos] = useState([]);
  const [points, setPoints] = useState(0);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [userName, setUserName] = useState("");
  const [copiado, setCopiado] = useState(false);
  const [itemValue, setItemValue] = useState(0);
  const [discount, setDiscountValue] = useState(0);
  const [released, setReleasedVaule] = useState(0);

  let count = 0;
  const currentDate = new Date().toString();
  const {
    group681Props,
    group682Props,
    buttonWithdrawAllEarning2Props,
    group1031Props,
    first_name,
    idUser,
    email,
  } = props;

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(
      `https://deploywk.herokuapp.com/plunder-customer-data/plunder/${email}/`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        setLastSix(response["lastsix"]["value"]);
        setDatos(response["lasttransactions"]);
        setUserName(response["username"]);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT");
        console.log(error);
        setLoading(false);
        setOpen(false);
        window.location.replace("/modal-disable");
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"].length != 0) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "PlunderPoints") {
              setPoints(bonus["amount"] - bonus["released"]);
            }
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"] - bonus["released"]);
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(Math.trunc(bonus["amount"]));
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setDiscountValue(Math.trunc(bonus["amount"]));
              setReleasedVaule(Math.trunc(bonus["released"]));
            }
          });
        }
        setLoading(false);
        setOpen(false);
      })
      .catch((res) => {
        console.log("EL USUARIO NO EXISTE DESDE EL CONTENT 2.0");
        setLoading(false);
        setOpen(false);
        window.location.replace("/modal-disable");
      });
  }, []);

  return (
    <>
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {!isLoading && (
        <div className="content">
          <div className="flex-col-1">
            <div className="flex-row-4">
              <div className="welcome avenir-book-normal-quick-silver-18px">
                Hello {first_name}, welcome back!
              </div>
              <div className="overlap-group5">
                <div className="group-89 border-0-5px-dove-gray">
                  <div className="plunderdesigncomxxxxxxx avenir-medium-black-19px reference-link">
                    stagingwave4.com/?sponsor={userName}
                  </div>
                </div>
                <div
                  className="x01-2"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(`stagingwave4.com/?sponsor=${userName}`)
                      .then(() => {
                        setCopiado(true);
                        console.log("Text copied to clipboard...");
                      })
                      .catch((err) => {
                        setCopiado(false);
                        console.log("Something went wrong", err);
                      });
                  }}
                >
                  <div className="dashboard-2 avenir-medium-cod-gray-18px">
                    {copiado ? "COPIED" : "COPY LINK"}
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-group7">
              <img className="rectangle-29" src="/img/rectangle-29@1x.png" />
              <div className="activity-logs-1 avenir-heavy-normal-eerie-black-20px">
                Points &amp; Rewards Balance
              </div>
            </div>
            <div className="flex-row-5">
              <div className="card-rewards">
                <div className="earning-report">
                  <div className="text-earning-this-month">
                    <div className="available-points avenir-book-normal-log-cabin-20px">
                      available points
                    </div>
                    <div className="overlap-group-16">
                      <div className="number bodoni72-bold-black-72px">
                        {((points + bonusPoints) - Math.trunc(points + bonusPoints)) ? Math.trunc(points + bonusPoints) + 1 : Math.trunc(points + bonusPoints)}
                      </div>
                      <ButtonWithdrawAllEarning2 />
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-group1-4">
                <div className="share-link">
                  <div className="text-earning-this-month-1">
                    <div className="group-1-1">
                      <div className="available-50-off-coupons avenir-book-normal-log-cabin-20px">
                        <b>{discount - released}</b> available 50% off coupons
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-container">
                  <Group68/>
                </div>
              </div>
              <div className="overlap-group2-2">
                <div className="share-link-1">
                  <div className="text-earning-this-month-2">
                    <div className="group-1-2">
                      <div className="exclusive-item-access avenir-book-normal-log-cabin-20px">
                        exclusive item access
                      </div>
                      <div className="overlap-group-17">
                        <div className="number-1 avenir-heavy-normal-white-32px">
                          {itemValue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ButtonWithdrawAllEarning2
                  className={buttonWithdrawAllEarning2Props.className}
                />
              </div>
            </div>
            <div className="overlap-group6">
              <div className="activity-logs-2 avenir-heavy-normal-eerie-black-20px">
                bonus points &amp; rewards progress
              </div>
            </div>
          </div>
          <div className="rightbar">
            <div className="earning-report-1">
              <div className="text-earning-this-month-3">
                <div className="address avenir-book-normal-black-20px">
                  6 month order total
                </div>
                <div className="overlap-group-18">
                  <h1 className="number-2 bodoni72-bold-black-60px">
                    {Math.round(lastsix)}
                  </h1>
                  <p className="address-1 montserrat-normal-quick-silver-15px">
                    {currentDate.split(" ").slice(0, 4).join(" ")}
                  </p>
                </div>
              </div>
            </div>
            <div className="schedule">
              <div className="title-1">
                <div className="schedule-1 avenir-black-eerie-black-24px">
                  Recent Activity
                </div>
                <div className="thursday-january-10th-2022 montserrat-medium-quick-silver-16px">
                  {currentDate.split(" ").slice(0, 4).join(", ")}
                </div>
              </div>
            </div>
            {datos.map((data, index) => {count += 1; return (<Group103
                      dateProps={group1031Props.dateProps}
                      key={index}
                      data={data}
                    />)}
                // console.log("1=>",data);
                // if (data["firstName"] && (data["customerType"] === "customer")) {
                //   count += 1;
                //   return (
                //     <Group103
                //       dateProps={group1031Props.dateProps}
                //       key={index}
                //       data={data}
                //     />
                //   );
                // }
              )}
            {!count && (
              <div className={`group-103`}>
                <div className="overlap-group1-6">
                  <div className="group-102">
                    <div className="overlap-group-26">
                      <div className="meeting-with-client-2 avenir-black-eerie-black-16px">
                        NO RECENT ACTIVITY
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

function copyToClickBoard() {
  var content = document.getElementById("textArea").innerHTML;

  navigator.clipboard
    .writeText(content)
    .then(() => {
      console.log("Text copied to clipboard...");
    })
    .catch((err) => {
      console.log("Something went wrong", err);
    });
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export default CONTENT;
