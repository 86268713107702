import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./Group88.css";

function Group88(props) {
  const { idUser } = props;
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [bonuses, setBonuses] = useState([]);

  const [percentCoupon, setPercentCoupon] = useState(0);
  const [itemCupon, setItemCupon] = useState(0);

  const [off, setOff] = useState(0);
  const [totalOff, setTotalOff] = useState(0);

  const [itemValue, setItemValue] = useState(0);
  const [totalItemValue, setTotalItemValue] = useState(0);

  useEffect(() => {
    setLoading(true);
    setOpen(true);

    fetch(`https://deploywk.herokuapp.com/plunder-bonuses/plunder/${idUser}/`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res["bonuses"].length != 0) {
          res["bonuses"].map((bonus, index) => {
            if (bonus["bonusId"] == "BonusPoints") {
              setBonusPoints(bonus["amount"]);
            }
            if (bonus["bonusId"] == "PlunderPoints") {
              setTotalPoints(bonus["amount"]);
            }
            if (bonus["bonusId"] == "PercentCoupon") {
              setOff(bonus["amount"]);
              setTotalOff(bonus["percent"]);
              let totalValuesSources = 0;
              bonus["details"].map((detail) => {
                totalValuesSources += parseFloat(detail["source"]["value"]);
              });
              totalValuesSources = totalValuesSources / 200;
              setPercentCoupon(totalValuesSources - Math.trunc(totalValuesSources));
            }
            if (bonus["bonusId"] == "ItemCoupon") {
              setItemValue(bonus["amount"]);
              setTotalItemValue(bonus["percent"]);
              let totalItemSources = 0;
              bonus["details"].map((detail) => {
                totalItemSources += parseFloat(detail["source"]["value"]);
              });
              totalItemSources = totalItemSources / 200;
              setItemCupon(totalItemSources - Math.trunc(totalItemSources));
            }
          });
        }
        setBonuses(res["bonuses"]);
        setLoading(false);
        setOpen(false);
      });
  }, []);

  return (
    <div className="group-88">
      {isLoading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {!isLoading && bonuses.length != 0 && (
        <div className="group-78">
          <div className="group-72">
            <div className="overlap-group-28">
              <div className="overlap-group-29">
                <progress
                  className="rectangle-32 border-2px-dove-gray barra"
                  max={10}
                  value={Math.round(totalPoints / 2 + 10 - (bonusPoints + 10))}
                ></progress>
                <img className="path-64" src="/img/path-64@1x.png" />
                <img className="icon-star-1" src="/img/path-65@1x.png" />
              </div>
              <div className="price avenir-heavy-normal-white-24px">
                ${Math.round(totalPoints / 2)}
              </div>
              <div className="price-1 avenir-heavy-normal-sonic-silver-16px">
                ${bonusPoints + 10}
              </div>
              <p className="x25-away-from-10-bonus-points avenir-book-normal-sonic-silver-14px">
                ${Math.round(bonusPoints + 10 - totalPoints / 2)} away from $
                {bonusPoints + 10} bonus points
              </p>
            </div>
            <div className="activity-logs-5 avenir-heavy-normal-eerie-black-20px">
              10 bonus points
            </div>
          </div>
          <div className="group-73">
            <div className="overlap-group-28">
              <div className="overlap-group-29">
                <progress
                  className="rectangle-32-1 border-2px-dove-gray barra-2"
                  max={200}
                  value={200 * percentCoupon}
                ></progress>
                <img className="path-64" src="/img/path-64-1@1x.png" />
                <img className="icon-label-1" src="/img/path-66@1x.png" />
              </div>
              <div className="price avenir-heavy-normal-white-24px">
                ${Math.round(200 * percentCoupon)}
              </div>
              <div className="price-1 avenir-heavy-normal-sonic-silver-16px">
                $200
              </div>
              <p
                className="x25-away-from-10-bonus-points avenir-book-normal-sonic-silver-14px"
                style={{ left: "508px" }}
              >
                ${Math.round(200 - (200 * percentCoupon) - percentCoupon)}
                {" "}
                away for the next off
              </p>
            </div>
            <div className="activity-logs-5 avenir-heavy-normal-eerie-black-20px">
              50% off an item
            </div>
          </div>
          <div className="group-75">
            <div className="overlap-group3-2">
              <div className="activity-logs-5 avenir-heavy-normal-eerie-black-20px">
                shop for an exclusive item
              </div>
              <div className="overlap-group1-8">
                <div className="overlap-group-32">
                  <progress
                    className="rectangle-32-2 border-2px-dove-gray barra-3"
                    max={200}
                    value={Math.round(200 * itemCupon)}
                  ></progress>
                  <img className="path-64" src="/img/path-64-2@1x.png" />
                  <img className="icon-heart" src="/img/path-67@1x.png" />
                </div>
                <div className="price-4 avenir-heavy-normal-white-24px">
                  ${Math.round(200 * percentCoupon)}
                </div>
                <div className="price-1 avenir-heavy-normal-sonic-silver-16px">
                  $200
                </div>
                <p
                  className="x25-away-from-10-bonus-points-2 avenir-book-normal-sonic-silver-14px"
                  style={{ left: "506px" }}
                >
                  ${Math.round(200 - (200 * percentCoupon))} away for the next item
                </p>
              </div>
            </div>
          </div>
          <div className="group-76">
            <div className="overlap-group4-3">
              <div className="activity-logs-5 avenir-heavy-normal-eerie-black-20px">
                posse box
              </div>
              <div className="group-74">
                <div className="overlap-group2-6">
                  <div className="overlap-group-33">
                    <img
                      className="rectangle-31-2"
                      src="/img/rectangle-31-3@1x.png"
                    />
                    <img
                      className="rectangle-32-3"
                      src="/img/rectangle-32@1x.png"
                    />
                    <div className="rectangle-33 border-2px-dove-gray"></div>
                    <img className="icon-gift" src="/img/path-68@1x.png" />
                    <img
                      className="rectangle-34"
                      src="/img/rectangle-34@1x.png"
                    />
                    <div className="rectangle-35 border-1px-dove-gray"></div>
                  </div>
                  <div className="x12months avenir-heavy-normal-sonic-silver-14px">
                    12
                    <br />
                    MONTHS
                  </div>
                  <div className="group-77">
                    <div className="overlap-group1-9">
                      <div className="rectangle-31-3 border-2px-dove-gray"></div>
                      <div className="rectangle-32-4 border-2px-dove-gray"></div>
                      <div className="rectangle-33-1 border-2px-dove-gray"></div>
                      <img className="path-64-2" src="/img/path-64-3@1x.png" />
                      <img
                        className="icon-gift-1"
                        src="/img/path-68-1@1x.png"
                      />
                      <div className="x20bonus-points avenir-heavy-normal-white-14px">
                        20
                        <br />
                        bonus points
                      </div>
                      <div className="x30bonus-points avenir-heavy-normal-white-14px">
                        30
                        <br />
                        bonus points
                      </div>
                      <div className="x50bonus-points avenir-heavy-normal-pale-sky-14px">
                        50
                        <br />
                        bonus points
                      </div>
                    </div>
                    <div className="x75bonus-points-gifts avenir-heavy-normal-pale-sky-14px">
                      75
                      <br />
                      bonus points
                      <br />+ gifts
                    </div>
                  </div>
                  <div className="x9months avenir-heavy-normal-sonic-silver-14px">
                    9<br />
                    MONTHS
                  </div>
                  <div className="x6months avenir-heavy-normal-sonic-silver-14px">
                    6<br />
                    MONTHS
                  </div>
                  <div className="x3months avenir-heavy-normal-sonic-silver-14px">
                    3<br />
                    MONTHS
                  </div>
                </div>
                <p className="address-4 avenir-book-normal-sonic-silver-14px">
                  8 consecutive months on posse
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {bonuses.length == 0 && (
        <div className="number bodoni72-bold-black-72px center-text">
          DOES NOT HAVE DATA TO SHOW
        </div>
      )}
    </div>
  );
}

export default Group88;
